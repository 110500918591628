const WEEKDAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const COUNTRIES = [
  {
    name: 'Norway',
    shortcut: 'no',
  },
  {
    name: 'Poland',
    shortcut: 'pl',
  },
];

export {
  COUNTRIES,
  WEEKDAYS,
};
