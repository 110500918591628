const getOnboardingQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/onboarding?language=:language`,
}

const getOnboardingTagsQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/onboarding/tags?recipeId=:recipeId&language=:language`,
}

const saveOnboardingQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/onboarding/tags`,
}

const saveOnboardingStatusDataQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/onboarding/status`,
}

export {
  getOnboardingQuery,
  getOnboardingTagsQuery,
  saveOnboardingQuery,
  saveOnboardingStatusDataQuery,
};
