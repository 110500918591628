import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from 'mui-rff';

export const getFormFields = (users, handleUserChange) => {
  return [
    {
      size: 6,
      field: (
        <Autocomplete
          disablePortal
          id="users-"
          options={users}
          getOptionLabel={option => option.nick}
          onChange={handleUserChange}
          renderInput={(params) => <TextField name="nick" label="User" {...params} required />}
        />
      )
    },
    {
      size: 6,
      field: (
        <TextField
          required
          type="source"
          label="Source URL"
          name="externalSourceUrl"
          margin="none"
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextField
          required
          type="url"
          label="Recipe photo URL"
          name="photoUrl"
          margin="none"
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextField
          required
          label="Recipe Title"
          name="title"
          margin="none"
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextField
          required
          multiline
          label="Description"
          name="description"
          margin="none"
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextField
          required
          type="number"
          label="Portions"
          name="portions"
          margin="none"
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextField
          required
          label="Preparation time(total)"
          name="timeTotal"
          margin="none"
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextField
          multiline
          label="Chief Notes"
          name="chiefNotes"
          margin="none"
        />
      ),
    },
  ];
}
