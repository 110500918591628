import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from 'mui-rff';

export const getFormFields = (users, handleUserChange) => {
  return [
    {
      size: 6,
      field: (
        <Autocomplete
          disablePortal
          id="users-"
          options={users}
          getOptionLabel={option => option.nick}
          onChange={handleUserChange}
          renderInput={(params) => <TextField name="nick" label="User" {...params} required />}
        />
      )
    },
    {
      size: 6,
      field: (
        <TextField
          required
          type="text"
          label="Event Name"
          name="name"
          margin="none"
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextField
          required
          type="text"
          label="Event Description"
          name="description"
          margin="none"
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextField
          required
          type="datetime-local"
          name="start"
          margin="none"
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextField
          required
          label="Duration"
          name="duration"
          margin="none"
        />
      ),
    },
  ];
}
