import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { login } from '../../data/controllers'
import logo from './../../images/logo.svg';


const useStyles = makeStyles((theme) => ({
  login: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  media: {
    filter: 'invert(0.15)',
  },
  loginButton: {
    backgroundColor: 'rgb(80, 129, 115)',
    color: '#FFF',
  }
}));

export function Login() {
  const history = useHistory();
  const classes = useStyles();
  const [formValues, setFormValues] = useState({});

  const handleTextFieldChange = (e, fieldName) => {
    setFormValues({
      ...formValues,
      [fieldName]: e.target.value,
    });
  }

  const submitForm = async() => {
    await login(formValues, history);
  }

  return (
    <form className={classes.login} noValidate autoComplete="off">
      <img
        className={classes.media}
        src={logo}
      />
      <TextField
        label="Email"
        variant="outlined"
        onChange={e => handleTextFieldChange(e, 'email')}
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        onChange={e => handleTextFieldChange(e, 'password')}
      />
      <Button variant="contained" onClick={submitForm} className={classes.loginButton}>
        Login
      </Button>
    </form>
  );
}
