import React, { useEffect, useState } from 'react';

import {
  MenuItem,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { SimpleModal, RegularList } from '../../components';
import {
  getTipsData,
  createNewTipData,
  deleteTipData,
  activateTipData,
} from '../../data/controllers/tips';

import { COUNTRIES } from '../../constants';

import { useStyles } from './tips.style';

const DEFAULT_LANGUAGE = 'pl';

export const Tips = () => {
  const [tips, setTips] = useState([]);
  const [newTip, setNewTip] = useState({ priority: 1, language: DEFAULT_LANGUAGE, active: 'no' });
  const [newTipModalVisibility, setNewTipModalVisibility] = useState(false);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);

  const classes = useStyles();

  const getTips = async country => {
    const { data: { tips } }  = await getTipsData(country);
    tips.forEach(tip => tip.name = tip.title);
    await setTips(tips);
  }

  useEffect(() => {
    getTips(DEFAULT_LANGUAGE);
  }, []);

  useEffect(() => {
    getTips(language);
  }, [
    language,
  ]);

  const handleRegularFieldChange = ({ target: { id: inputId, value }}) => {
    setNewTip({
      ...newTip,
      [inputId]: value,
    });
  }

  const saveNewTip = async () => {
    newTip.active = newTip.active === 'yes';

    if(!newTip.title || !newTip.description || !newTip.language) {
      setError('Please fill the whole tip data')
    } else {
      await createNewTipData(newTip);
      setNewTip({ priority: 1, language: DEFAULT_LANGUAGE, active: 'no' });

      setNewTipModalVisibility(false);
      getTips(language);
    }
  }

  const handleActivateTip = async(e, tipId) => {
    await activateTipData(tipId);
    await getTips(language);
  };

  const handleDeleteTip = async id => {
    await deleteTipData({ id });
    await getTips(language);
  };

  const renderLanguagesOptions = (short = false) => {
    return COUNTRIES.map(({ name, shortcut }, idx) => {
      return <MenuItem
        key={`${shortcut}_${idx}`}
        value={shortcut}
      >
        {short ? shortcut : name}
      </MenuItem>
    });
  }

  return (
    <div className={classes.contentContainer}>
      <SimpleModal
        isOpened={newTipModalVisibility}
        onClose={() => setNewTipModalVisibility(false)}
        title="Add new tip"
        submit={saveNewTip}
        submitButtonText="Save"
      >
        <div className={classes.modalContentContainer}>
          <div className={classes.column} style={{ paddingTop: 24 }}>
            <TextField
              label="title"
              className={classes.textField}
              id="title"
              size="small"
              onChange={handleRegularFieldChange}
              value={newTip.title || ""}
            />
            <TextField
              label="description"
              id="description"
              size="small"
              className={classes.textField}
              onChange={handleRegularFieldChange}
              value={newTip.description || ""}
            />
            <TextField
              label="priotiry (the higher the more important)"
              id="priority"
              size="small"
              className={classes.textField}
              onChange={handleRegularFieldChange}
              value={newTip.priority || ""}
            />
            <div className={classes.formDropdown}>
              <Tooltip title={'Language'}>
                <FormControl>
                  <Typography
                    variant="body"
                  >
                    Language
                  </Typography>
                  <Select
                    className={classes.categorySelectInput}
                    labelId="language-label"
                    value={newTip.language || DEFAULT_LANGUAGE}
                    onChange={e => setNewTip({ ...newTip, language: e.target.value })}
                  >
                    {renderLanguagesOptions(true)}
                  </Select>
                </FormControl>
              </Tooltip>
            </div>
            <div className={classes.formDropdown}>
              <Tooltip title={'Active'}>
                <FormControl>
                  <Typography
                    variant="body"
                  >
                    Active
                  </Typography>
                  <Select
                    className={classes.categorySelectInput}
                    labelId="active-label"
                    value={newTip.active}
                    onChange={e => setNewTip({ ...newTip, active: e.target.value })}
                  >
                    <MenuItem key="active_yes" value={'yes'}>
                      yes
                    </MenuItem>
                    <MenuItem key="active_no" value={'no'}>
                      no
                    </MenuItem>
                  </Select>
                </FormControl>
              </Tooltip>
            </div>
          </div>
        </div>
        {!!error ? <p style={{ color: 'red' }}>{error}</p> : null}
      </SimpleModal>
      <div className={classes.contentContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.placesHeader}>
            <h3>TIPS</h3>
            <Typography
              variant="h6"
              onClick={() => setNewTipModalVisibility(true)}
              className={classes.addTagButton}
            >
              <Add />Tip
            </Typography>
          </div>
        </div>
        <div>
          <FormControl>
            <InputLabel id="language">Language</InputLabel>
            <Select
              labelId="language"
              value={language}
              onChange={e => setLanguage(e.target.value)}
            >
              {renderLanguagesOptions()}
            </Select>
          </FormControl>
        </div>
        {tips.length ? (
          <div className={classes.listsWrapper}>
            <div className={classes.list}>
              <RegularList
                items={tips}
                areItemsEditable={false}
                areItemsActivable={true}
                handleItemActivityChange={handleActivateTip}
                handleDeleteItem={handleDeleteTip}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
