import React from 'react';
import { Checkbox, withStyles } from '@material-ui/core';

export const ColorCheckbox = withStyles({
  root: {
    color: 'rgb(80,129,115)',
    '&$checked': {
      color: 'rgb(80,129,115)',
    },
  },
  checked: {},
})((props) =>
  <Checkbox color="default" {...props} />);
