const getRecipeQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/recipes?offset=:offset&selected_status=:selectedStatus&lang=:recipesLang`,
};

const saveStatusQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/recipes/status`,
};

const saveRecipeQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/recipes`,
};

const scrapRecipeQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/recipes/scrap`,
};

export {
  getRecipeQuery,
  saveStatusQuery,
  saveRecipeQuery,
  scrapRecipeQuery,
};
