import executeQuery from '../../data/api';
import { getOnboardingQuery, getOnboardingTagsQuery, saveListQuery, saveOnboardingQuery, saveOnboardingStatusDataQuery } from '../queries';


const getOnboardingData = async language => {
  try {
    return executeQuery(getOnboardingQuery, {
      params: {
        language,
      }
    });
  } catch(error) {
    return Promise.reject(error);
  }
};

const getOnboardingTagsData = async (recipeId, language) => {
  try {
    return executeQuery(getOnboardingTagsQuery, {
      params: {
        recipeId,
        language,
      },
    });
  } catch(error) {
    return Promise.reject(error);
  }
};

const saveOnboardingData = async body => {
  try {
    return executeQuery(saveOnboardingQuery, { body });

  } catch(error) {
    return Promise.reject(error);
  }
}

const saveOnboardingStatusData = async body => {
  try {
    return executeQuery(saveOnboardingStatusDataQuery, { body });

  } catch(error) {
    return Promise.reject(error);
  }
}


export {
  getOnboardingData,
  getOnboardingTagsData,
  saveOnboardingData,
  saveOnboardingStatusData,
};

