const getFoodProductsQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_products`,
};

const changeFoodProductActivityQuery = {
  method: 'PATCH',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_products/activity`,
};

const editFoodProductQuery = {
  method: 'PATCH',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_product`,
};

const deleteFoodProductQuery = {
  method: 'DELETE',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_product`,
};

const getFoodProvidersQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_providers`,
};

const changeFoodProviderActivityQuery = {
  method: 'PATCH',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_providers/activity`,
};

const editFoodProviderQuery = {
  method: 'PATCH',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_provider`,
};

const deleteFoodProviderQuery = {
  method: 'DELETE',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_provider`,
};

const createNewFoodProviderQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_provider`,
};

const getFoodStoresQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_stores`,
};

const changeFoodStoreActivityQuery = {
  method: 'PATCH',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_stores/activity`,
};

const editFoodStoreQuery = {
  method: 'PATCH',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_store`,
};

const deleteFoodStoreQuery = {
  method: 'DELETE',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_store`,
};

const createNewFoodStoreQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_store`,
};

const createNewFoodProductQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/local/food_product`,
};

const getPlacesQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/places?country=:country`,
};

const requestNewPlaceQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/places`,
};


const createNewPlaceQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/places/:id`,
};


const deletePlaceQuery = {
  method: 'DELETE',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/places/:id`,
};


const editPlaceQuery = {
  method: 'PATCH',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/places/:id`,
};


export {
  changeFoodProductActivityQuery,
  getFoodProductsQuery,
  editFoodProductQuery,
  deleteFoodProductQuery,
  createNewFoodProductQuery,
  getFoodProvidersQuery,
  changeFoodProviderActivityQuery,
  editFoodProviderQuery,
  deleteFoodProviderQuery,
  createNewFoodProviderQuery,
  getFoodStoresQuery,
  changeFoodStoreActivityQuery,
  editFoodStoreQuery,
  deleteFoodStoreQuery,
  createNewFoodStoreQuery,
  getPlacesQuery,
  requestNewPlaceQuery,
  createNewPlaceQuery,
  deletePlaceQuery,
  editPlaceQuery,
};
