const getTipsQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/tips?language=:language`,
};

const createNewTipQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/tips`,
};

const activateTipQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/tips/:id/activate`,
};

const deleteTipQuery = {
  method: 'DELETE',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/tips/:id`,
};




export {
  getTipsQuery,
  createNewTipQuery,
  deleteTipQuery,
  activateTipQuery,
};
