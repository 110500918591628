import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { TextField ,} from 'mui-rff';
import { Button, CssBaseline, Grid, Paper, Dialog } from '@material-ui/core';
import isURL from 'is-url';
import { AddCircleOutline, CheckCircle } from '@material-ui/icons';

import { getUsersData } from '../../data/controllers';
import {
  saveRecipeDate,
  scrapRecipeData,
} from '../../data/controllers';
import { getFormFields } from './AddRecipeFields';

import { useStyles } from './addRecipe.style';


export const AddRecipe = () => {
  const classes = useStyles();
  const [ingredients, setIngredients] = useState([{ group: true }]);
  const [stages, setStages] = useState([{}]);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [scrappedData, setScrappedData] = useState({});
  const [dialogVisibility, setDialogVisibility] = useState(false);

  const onSubmit = async (submitValues, form) => {
    submitValues.user = {
      id: user.id,
      nick: user.nick,
    }

    const outputIngredients = submitValues?.ingredients || ingredients;
    const modIngredients = outputIngredients?.map((ingredient, idx) => {
      return {
        order: Number(idx),
        group: ingredient.group,
        name: ingredient.value,
      };
    });

    const outputStages = submitValues.stages || stages;
    const modStages = outputStages?.map((stage, idx) => {
      return {
        stage_number: Number(idx) + 1,
        description: stage.value,
        imgSrc: stage.imgSrc,
      };
    });

    submitValues.ingredients = modIngredients;
    submitValues.stages = modStages;

    const responseData = await saveRecipeDate({
      recipe: submitValues,
    });

    setIngredients([{ value: '', group: true }]);
    setStages([{ value: '' }]);
    setScrappedData({});
    form.reset();
    if(!responseData.data.error) setDialogVisibility(true);
  };

  const handleUserChange = (e, value) => {
    setUser(value);
  };

  const getUsers = async() => {
    const { data: { users } } = await getUsersData();

    setUsers(users);
  };

  const addIngredient = (type, index) => {
    setIngredients([
      ...ingredients,
      {
        group: type === 'group',
        order: index,
      },
    ]);
  };

  const addStage = (type, index) => {
    setStages([
      ...stages,
      { order: index },
    ]);
  };

  const handleIngredientsChange = (e, group, index) => {
    ingredients[index]['group'] = group;
    ingredients[index]['order'] = index;
    ingredients[index]['value'] = e.target.value;

    setIngredients([ ...ingredients ]);
  };

  const handleStagesChange = (e, index) => {
    stages[index]['value'] = e.target.value;
    stages[index]['order'] = index;

    setStages([ ...stages ]);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleScraping = async url => {
    const data = await scrapRecipeData({ url });

    if(data) {
      const { data: { results } } = data;
      setScrappedData(results);
      setIngredients(results?.ingredients);
      setStages(results?.stages);
    }
  };

  return (
    <div className={classes.contentContainer}>
      <Dialog open={dialogVisibility} onClose={() => setDialogVisibility(false)}>
        <Grid container alignItems="center" spacing={2} className={classes.dialog}>
          <CheckCircle color="success" fontSize="large" style={{ color: 'green' }} />
          <h4>Recipe has been saved.</h4>
        </Grid>
      </Dialog>
      <CssBaseline />
      <Form
        onSubmit={onSubmit}
        initialValues={scrappedData}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Paper style={{ padding: 16 }}>
              <Grid>
                <Grid container alignItems="flex-start" spacing={2} className={classes.fieldsContentContainer}>
                  <Grid item xs="9">
                    <h3 className={classes.header}>Scrap new recipe</h3>
                  </Grid>
                  <Grid item xs="3">
                    {values?.photoUrl && <img src={values.photoUrl} className={classes.previewImg} />}
                  </Grid>
                  {getFormFields(users, handleUserChange).map((item, idx) => (
                    <Grid item xs={item.size} key={idx}>
                      {item.field}
                    </Grid>
                  ))}
                  <Grid item xs="6" className={classes.ingredients}>
                    <h6>Ingredients</h6>
                    {ingredients?.map(({ group }, index) => {
                      return (
                        <Grid container alignItems="flex-start" justify="center" spacing={1}>
                          <Grid item xs="8">
                            <TextField
                              name={`ingredient-${index + 1}`}
                              className={ingredients[index].group ? classes.textField : null}
                              onChange={e => handleIngredientsChange(e, group, index)}
                              value={ingredients[index].value}
                            />
                          </Grid>
                          <Grid item xs="4">
                            {(index === ingredients.length - 1)
                              ? <AddCircleOutline onClick={() => addIngredient('item', index)}/>
                              : null}
                            {(index === ingredients.length - 1)
                              ? <AddCircleOutline style={{ color: 'red' }} onClick={() => addIngredient('group', index)}/>
                              : null}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid item xs="6" className={classes.stages}>
                    {stages?.map((stage, index) => {
                      return (
                        <Grid container alignItems="flex-start" spacing={1} className={classes.stageContainer}>
                          <Grid item xs="12" className={classes.stageHeader}>
                            <h6>{`Stage ${index + 1}`}</h6>
                          </Grid>
                          <Grid item xs="10" className={classes.stagesTextField}>
                            <TextField
                              multiline
                              name={`stage-${index + 1}`}
                              onChange={e => handleStagesChange(e, index)}
                              value={stages[index].value}
                            />
                          <Grid item xs="10">
                            {stage.imgSrc && <img src={stage.imgSrc} className={classes.listPreviewImg} />}
                          </Grid>
                          </Grid>
                          <Grid item xs="2">
                            {(index === stages.length - 1) ? <AddCircleOutline onClick={addStage}/> : null}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <div className={classes.buttons}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => handleScraping(values.externalSourceUrl)}
                    disabled={!isURL(values?.externalSourceUrl)}
                  >
                    Try to scrap
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Submit
                  </Button>
                </div>
              </Grid>
            </Paper>
          </form>
        )}
      />
    </div>
  );
}
