import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(theme => ({
  root: {
    width: 750,
    margin: '0 auto',
    marginTop: 20,
    marginBottom: 20,
    overflow: 'visible',
  },
  tagsSelect: {
    width: 100,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  header: {
    marginTop: 30,
  },
  avatar: {
    backgroundColor: '#FFCCCB',
  },
  pagination: {
    margin: '40px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    paddingTop: 0,
  },
  expandMoreIcon: {
    rotated: {
      transform: 'rotate(180deg)',
    },
    nonRotated: {
      transform: 'rotate(0deg)',
    }
  },
  buttonSection: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 20,
  },
  formsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  previousArrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '7rem',
    zIndex: 1,
    left: '10%',
    color: '#cccccc',
    cursor: 'pointer',
  },
  nextArrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '7rem',
    zIndex: 1,
    right: '10%',
    color: '#cccccc',
    cursor: 'pointer',
  },
  cardContainer: {
    position: 'relative',
    overflow: 'visible',
  },
  previousButton: {
    position: 'absolute',
    fontSize: '1rem',
    top: '25px',
    backgroundColor: '#FFF',
    boxShadow: '0px 3px 15px rgba(0,0,0,0.2)'
  },
  nextButton: {
    fontSize: '1rem',
    position: 'absolute',
    top: '25px',
    right: 0,
    backgroundColor: '#FFF',
    boxShadow: '0px 3px 15px rgba(0,0,0,0.1)',
  },
  newListInput: {
    width: '100%',
    marginBottom: 20,
  },
  languageField: {
    width: '100%',
    marginBottom: 30,
  },
  modalFormControl: {
    display: 'flex',
    flexDirection: 'column',
  },
  saveButton: {
    backgroundColor: 'rgb(139,158,162)',
    color: '#FFF',
  },
  acceptButton: {
    backgroundColor: 'rgb(80,129,115)',
    color: '#FFF',
    margin: '0 10px 0 10px',
  },
  rejectButton: {
    backgroundColor: '#E45C45',
    color: '#FFF',
  },
  recipeIframe: {
    width: '100%',
    height: 750,
    border: 'none',
  },
  filtersBar: {
    position: 'absolute',
    top: '65px',
    width: '100px',
    margin: '20px 0 0 20px',
    '& h4': {
      margin: '0 30px 15px 0',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '1rem',
    },
    '& .MuiFormControl-root': {
      marginBottom: '15px',
      width: '100px',
    }
  },
  noRecipesAvailable: {
    position: 'absolute',
    top: '50vh',
    left: '50vw',
    transform: 'translate(-50%)',
  },
  goBack: {
    width: '100%',
    border: '1px solid lightgray',
  },
  addRecipe: {
    position: 'absolute',
    right: '30px',
    top: '90px',
    color: 'rgb(80,129,115)',
    fontSize: '18px',
  },
  tagsTilesSection: {
    position: 'absolute',
    left: 'calc(100% + 15px)',

    '& button': {
      border: '1px solid lightgray',
      marginBottom: 4,
      fontSize: '0.8rem',
    },
    '& h4': {
      fontSize: '1rem',
      color: 'rgba(0, 0, 0, 0.54)',
    }
  },
  listsTilesSection: {
    position: 'absolute',
    right: 'calc(100% + 15px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',

    '& button': {
      border: '1px solid lightgray',
      marginBottom: 4,
      display: 'block',
      fontSize: '0.8rem',
    },
    '& h4': {
      fontSize: '1rem',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
}));
