import executeQuery from '../../data/api';
import {
  getTipsQuery,
  createNewTipQuery,
  deleteTipQuery,
  activateTipQuery,
} from '../queries/tips';


const getTipsData = async language => {
  try {
    const data = await executeQuery(getTipsQuery, {
      params: {
        language,
      },
    });
    return data;
  } catch(error) {
    return Promise.reject(error);
  }
};


const createNewTipData = async data => {
  try {
    const { data: { tip: { id } } } = await executeQuery(createNewTipQuery, { body: { 
      ...data,
    }});

    return id;
  } catch(error) {
    return Promise.reject(error);
  }
}


const activateTipData = async id => {
  try {
    const data = await executeQuery(activateTipQuery, {
      params: { id },
    });

    return data;
  } catch(error) {
    return Promise.reject(error);
  }
}


const deleteTipData = async body => {
  try {
    return executeQuery(deleteTipQuery, { params: {
      id: body.id,
    }});
  } catch(error) {
    return Promise.reject(error);
  }
};


export {
  getTipsData,
  createNewTipData,
  activateTipData,
  deleteTipData,
};
