import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


import { makeStyles } from '@material-ui/core/styles';
import { SaveAlt } from '@material-ui/icons';
import { BottomNavigation, BottomNavigationAction, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import {
  getOnboardingData,
  getOnboardingTagsData,
  getTagsData,
  saveOnboardingData,
} from '../../data/controllers';
import { SelectedTags } from '../../components';
import { LANGUAGES, DEFAULT_LANGUAGE } from '../../constants';



const useStyles = makeStyles((theme) => ({
  contentContainer: {
    maxWidth: 1100,
    margin: '0 auto',
    marginTop: 100,
  },
  recipesContainer: {
    display: 'flex',
  },
  pictureContainer: {
    cursor: 'pointer',
  },
  picture: {
    borderRadius: 5,
    width: 100,
    height: 150,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    opacity: '0.9',
  },
  overlay: {
    opacity: '1',
  },
  bottomNavigation: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    '& button': {
      maxWidth: '100%',
    },
  },
  languages: {
    marginBottom: 30,
  },
}));

const GRID = 12;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  marginRight: GRID,
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'rgba(80,129,115,0.1)' : '#FAFAFA',
  display: 'flex',
  padding: GRID,
  overflow: 'scroll',
});


export const Onboarding = () => {
  const classes = useStyles();
  const [onboardingData, setOnboardingData] = useState([]);
  const [selectedTile, setSelectedTile] = useState(null);
  const [preselectedTags, setPreselectedTags] = useState([]);
  const [chosenTags, setChosenTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);


  const handleTileSelecting = async id => {
    const { data: { tags } } = await getTagsData();
    setTags(tags);

    const { data: { onboardingTags }} = await getOnboardingTagsData(id, 'pl');
    setPreselectedTags(onboardingTags);
    setSelectedTile(id);
  }

  useEffect(async() => {
    const { data: { onboardingData } } = await getOnboardingData(language);
    setOnboardingData(onboardingData);
  }, [
    language,
  ]);

  const renderOnboardingTiles = () => {
    return onboardingData?.map(({ original_picture_url: originalPictureUrl, id }, index) => {
      return (
        <Draggable key={id} draggableId={id.toString()} index={index}>
          {(provided, snapshot) => (
            <div className={classes.pictureContainer} onClick={() => handleTileSelecting(id)}>
              <img
                src={originalPictureUrl}
                className={clsx(classes.picture, (id === selectedTile) && classes.overlay )}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style,
                  originalPictureUrl,
                )}
              />
            </div>
          )}
        </Draggable>
      );
    })
  }

  const handleSave = async() => {
    const reducedTags = chosenTags.map(tag => tag.id);
    await saveOnboardingData({
      tagsIds: reducedTags,
      recipeId: selectedTile,
    });
  }

  const renderLanguageOptions = () => {
    return LANGUAGES.map(({ name, shortcut }, idx) => {
      return <MenuItem
        key={`${shortcut}_${idx}`}
        value={shortcut}
      >
        {name}
      </MenuItem>
    });
  }

  const onLanguageChange = e => {
    setLanguage(e.target.value);
    setSelectedTile(null);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    if(!result.destination) {
      return;
    }

    const items = reorder(
      onboardingData,
      result.source.index,
      result.destination.index
    );

    setOnboardingData(items);
  }

  return (
    <Fragment>
      <div className={classes.contentContainer}>
        <FormControl className={classes.languages}>
          <InputLabel id="language">Language</InputLabel>
          <Select
            labelId="language"
            value={language}
            onChange={onLanguageChange}
          >
            {renderLanguageOptions()}
          </Select>
        </FormControl>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                <div className={classes.recipesContainer}>
                  {renderOnboardingTiles()}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!!selectedTile && (
          <SelectedTags
            visible={!!selectedTile}
            tags={tags}
            preselectedTags={preselectedTags}
            onSelectedTags={selectedTags => setChosenTags(selectedTags)}
          />)}
      </div>
      {!!selectedTile && (
        <BottomNavigation showLabels className={classes.bottomNavigation} onClick={handleSave}>
          <BottomNavigationAction label="Save" icon={<SaveAlt />}/>
        </BottomNavigation>
      )}
    </Fragment>
  );
}
