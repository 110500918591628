import React, { Fragment, useEffect } from 'react';
import { Switch, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { loadProgressBar } from 'axios-progress-bar';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { Header } from './components';
import {
  AddRecipe,
  Dashboard,
  Login,
  Lists,
  Content,
  Onboarding,
  Tags,
  Moderation,
  FoodProviders,
  FoodStores,
  FoodProducts,
  Events,
  Places,
  Reports,
  Tips,
} from './views';
import history from './history';

import 'axios-progress-bar/dist/nprogress.css';
import './App.css';


const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100vh',
        },
        body: {
          minHeight: '100vh',
          padding: 0,
          margin: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: '"Lato", sans-serif',
        },
      },
    },
  },
  typography: {
    fontFamily: '"Lato", sans-serif',
  },
});


function App() {
  useEffect(() => {
    loadProgressBar();
  }, []);

  return (
    <BrowserRouter history={history}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Switch>
          <Route exact path="/" component={Login} />
          <Fragment>
            <Header />
            <Route exact path="/recipes" component={Dashboard} />
            <Route path="/recipes/add" component={AddRecipe} />
            <Route path="/lists" component={Lists} />
            <Route path="/tags" component={Tags} />
            <Route path="/content" component={Content} />
            {/*<Route path="/onboarding" component={Onboarding} />*/}
            <Route path="/moderation" component={Moderation} />
            <Route path="/food_products" component={FoodProducts} />
            <Route path="/food_providers" component={FoodProviders} />
            <Route path="/food_stores" component={FoodStores} />
            <Route path="/places" component={Places} />
            <Route path="/tips" component={Tips} />
            <Route path="/streaming" component={Events} />
            <Route path="/reports" component={Reports} />
          </Fragment>
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
