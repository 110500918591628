import executeQuery from '../../data/api';
import {
  changeListActivityQuery,
  createListQuery,
  deleteListQuery,
  editListQuery,
  getListTagsQuery,
  getListsQuery,
  saveListQuery,
  saveListsQuery,
  saveListsOrderQuery,
} from '../queries';


const getListsData = async lang => {
  try {
    return executeQuery(getListsQuery, { params: { lang }});

  } catch(error) {
    return Promise.reject(error);
  }
};

const saveListsData = async body => {
  try {
    return executeQuery(saveListsQuery, { body });

  } catch(error) {
    return Promise.reject(error);
  }
};

const saveListsOrderData = async body => {
  try {
    return executeQuery(saveListsOrderQuery, { body });

  } catch(error) {
    return Promise.reject(error);
  }
};

const createListData = async body => {
  try {
    return executeQuery(createListQuery, { body });

  } catch(error) {
    return Promise.reject(error);
  }
}

const deleteListData = async body => {
  try {
    return executeQuery(deleteListQuery, { body });

  } catch(error) {
    return Promise.reject(error);
  }
}

const editListData = async body => {
  try {
    return executeQuery(editListQuery, { body });

  } catch(error) {
    return Promise.reject(error);
  }
}

const changeListActivityData = async body => {
  try {
    return executeQuery(changeListActivityQuery, { body });

  } catch(error) {
    return Promise.reject(error);
  }
}

const saveListData = async body => {
  try {
    return executeQuery(saveListQuery, { body });

  } catch(error) {
    return Promise.reject(error);
  }
}

const getListTagsData = async params => {
  try {
    return executeQuery(getListTagsQuery, { params });

  } catch(error) {
    return Promise.reject(error);
  }
}


export {
  changeListActivityData,
  createListData,
  deleteListData,
  editListData,
  getListsData,
  getListTagsData,
  saveListData,
  saveListsData,
  saveListsOrderData,
};
