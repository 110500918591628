const getTagsQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/tags`,
};

const getTagsCategoriesQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/tags/categories`,
}

const saveTagsQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/tags/recipe`,
}

const createNewTagQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/tags`,
}

const createNewTagCategoryQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/tags/category`,
}

const assignTagCategoryQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/tags/category/assign`,
}

const deleteTagQuery = {
  method: 'DELETE',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/tags`,
}

const editTagQuery = {
  method: 'PATCH',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/tags`,
}

const changeTagAcitivityQuery = {
  method: 'PATCH',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/tags/activity`,
}

export {
  assignTagCategoryQuery,
  createNewTagCategoryQuery,
  changeTagAcitivityQuery,
  createNewTagQuery,
  editTagQuery,
  deleteTagQuery,
  getTagsQuery,
  getTagsCategoriesQuery,
  saveTagsQuery
};
