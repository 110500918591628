import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  tagItem: {
    marginRight: 10,
  },
  contentContainer: {
    maxWidth: 650,
    margin: '0 auto',
    marginTop: 100,
  },
  createButton: {
    backgroundColor: 'rgb(80,129,115)',
    color: '#FFF',
    '&:hover': {
      background: 'rgb(56,97,87)',
    },
  },
  addTagButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginRight: 30,
  },
  tagTextContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 120,
    marginRight: 50,
  },
  tagsHeaderContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tagsHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 40,
  },
  link: {
    fontSize: '1.25rem',
  },
  recipesCount: {
    color: 'rgb(80, 129, 115)',
  },
  checkbox: {
    color: 'rgb(80, 129, 115) !important',
  },
  listsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  secondaryRoot: {
    right: '-300px !important',
  },
  categorySelectInput: {
    width: 150,
  },
}));
