import executeQuery from '../../data/api';
import {
  changeEventActivityQuery,
  createEventQuery,
  deleteEventQuery,
  editEventQuery,
  getEventsQuery,
  saveEventsQuery,
} from '../queries';


const getEventsData = async () => {
  try {
    return executeQuery(getEventsQuery);

  } catch(error) {
    return Promise.reject(error);
  }
};

const createEventData = async body => {
  try {
    return executeQuery(createEventQuery, { body });

  } catch(error) {
    return Promise.reject(error);
  }
}

const deleteEventData = async body => {
  try {
    return executeQuery(deleteEventQuery, { body });

  } catch(error) {
    return Promise.reject(error);
  }
}

const editEventData = async body => {
  try {
    return executeQuery(editEventQuery, { body });

  } catch(error) {
    return Promise.reject(error);
  }
}

const changeEventActivityData = async body => {
  try {
    return executeQuery(changeEventActivityQuery, { body });

  } catch(error) {
    return Promise.reject(error);
  }
}

const saveEventsData = async body => {
  try {
    return executeQuery(saveEventsQuery, { body });

  } catch(error) {
    return Promise.reject(error);
  }
};


export {
  changeEventActivityData,
  createEventData,
  deleteEventData,
  editEventData,
  getEventsData,
  saveEventsData,
};
