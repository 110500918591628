import executeQuery from '../../data/api';
import { getUsersQuery } from '../queries';


const getUsersData = async () => {
  try {
    return executeQuery(getUsersQuery);
  } catch(error) {
    return Promise.reject(error);
  }
};

export {
  getUsersData,
};


