import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import logo from './../../images/logo.svg';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
  },
  toolbarLeft: {
    display: 'flex',
    alignItems: 'center',

    '& img': {
      marginRight: '20px',
      width: '60px',
      filter: 'invert(0.25)'
    },
  },
  divider: {
    marginRight: '20px',
    backgroundColor: 'lightgray',
    width: '1px',
    height: '30px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  addList: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& a, h6': {
      color: '#000',
      textDecoration: 'none',
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        height: '1.3em',
      },
    }
  },
  menuItem: {
    marginRight: 30,
    display: 'flex',
    alignItems: 'center',
  },
  logout: {
    fontSize: '1.1rem',
  },
  link: {
    fontSize: '1.1rem',
    marginRight: '2rem',
    padding: 0,
    color: '#000',
    textTransform: 'uppercase',
    margin: 0,

    '&:hover': {
      textDecoration: 'none',
    },

    '& li': {
      '&:hover': {
        textDecoration: 'none',
        color: '#000',
      },
    },

    '& a': {
      lineHeight: '1.1rem',
    },
  },
  localMenu: {
    '& ul': {
      padding: '0 !important',
      margin: '0 !important',
      display: 'flex',
      flexDirection: 'column',

      '& li': {
        padding: '12px 24px',
      },
    },

    '& a': {
      margin: 0,
    },

    '& .MuiPaper-root': {
      top: '50px !important',
    }
  },
}));

export const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);


  const onLogout = async() => {
    const logoutResult = await axios.get('/admin/auth/logout');
    logoutResult.status === 200 && history.push('/');
  }

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarLeft}>
            <img src={logo} />
            <div className={classes.divider}/>
            <div className={classes.menu}>
              <Link to="/lists">
                <Link to="/recipes" className={classes.link}>
                  Recipes
                </Link>
                <Link to="/lists" className={classes.link}>
                  Lists
                </Link>
                <Link to="/tags" className={classes.link}>
                  Tags
                </Link>
                <Link to="/places" className={classes.link}>
                  Places
                </Link>
                <Link to="/tips" className={classes.link}>
                  Tips
                </Link>
                {/* <Link to="/content" className={classes.link}>
                  content
                </Link> */}
                {/*<Link to="/onboarding" className={classes.link}>*/}
                {/*  onboarding*/}
                {/*</Link>*/}
                {/* <Link to="/moderation" className={classes.link}>
                  moderation
                </Link>
                <Link to="/streaming" className={classes.link}>
                  streaming
                </Link> */}

                {/* <div>
                  <Button className={classes.link} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    Local
                  </Button>
                  <Menu
                    id="simple-menu"
                    className={classes.localMenu}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <Link to="/food_products" className={classes.link}>
                      <MenuItem onClick={handleClose}>Food Products</MenuItem>
                    </Link>
                    <Link to="/food_providers" className={classes.link}>
                      <MenuItem onClick={handleClose}>Food Providers</MenuItem>
                    </Link>
                    <Link to="/food_stores" className={classes.link}>
                      <MenuItem onClick={handleClose}>Food Stores</MenuItem>
                    </Link>
                    <Link to="/places" className={classes.link}>
                      <MenuItem onClick={handleClose}>Places</MenuItem>
                    </Link>
                  </Menu>
                </div> */}

                <Link to="/reports" className={classes.link}>
                  Reports
                </Link>
              </Link>
            </div>
          </div>
          <Button className={classes.logout} onClick={() => onLogout()}>Logout</Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
