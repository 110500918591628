import React, { useState } from 'react';
import { EditorState, ContentState } from 'draft-js';
import { RichTextEditor } from '../../components';
import { TextField } from '@mui/material';


import { useStyles } from './content.style';


export const Content = () => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState({});


  const handleNewArticleFields = e => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleRichText = richTextValue => {
    setFormValues({
      ...formValues,
    });
  };


  return (
    <div className={classes.root}>
      <TextField
        name="title"
        label="title"
        id="title"
        onChange={handleNewArticleFields}
      />
      <TextField
        name="description"
        label="description"
        id="description"
        onChange={handleNewArticleFields}
      />
      <RichTextEditor
        passEditorState={handleRichText}
      />
    </div>
  );
}
