import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(theme => ({
  productsHeaderContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  productsHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 40,
  },
  addTagButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginRight: 30,
  },
  listsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkbox: {
    color: 'rgb(80, 129, 115) !important',
  },
  foodProductTextContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginRight: 50,
  },
  productItem: {
    marginRight: 10,
  },
  secondaryRoot: {
    right: '-300px !important',
  },
  productTextField: {
    width: 250,
  },
}));
