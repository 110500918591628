import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 14,
    marginTop: 100,
  },
  tags: {
    backgroundColor: 'rgba(157, 203, 193, 0.09)',
    margin: '0 20px 12px 0',
    padding: '6px 16px',
    borderRadius: 6,
    cursor: 'pointer',
  },
  tagsSelected: {
    backgroundColor: 'rgb(80, 129, 115)',
    color: '#FFF',
    margin: '0 20px 12px 0',
    padding: '6px 16px',
    borderRadius: 6,
    cursor: 'pointer',
  },
}));

export const SelectedTags = ({
  visible = false,
  tags = [],
  preselectedTags = [],
  onSelectedTags = () => {},
}) => {
  const classes = useStyles();
  const [selectedTags, setSelectedTags] = useState([]);

  const handleSelectedTags = tag => {
    const wasTagSelected = selectedTags.some(selectedTag => selectedTag.id === tag.id);

    if(wasTagSelected) {
      const reducedTags = selectedTags.filter(selectedTag => selectedTag.id !== tag.id);
      onSelectedTags(reducedTags);
      return setSelectedTags(reducedTags);
    }

    const selected = [
      ...selectedTags,
      tag,
    ];

    setSelectedTags(selected);
    onSelectedTags(selected);
  }

  useEffect(() => {
    setSelectedTags(preselectedTags);
  }, [
    preselectedTags,
  ]);


  const renderTagsOptions = () => {
    return tags?.map((tag, idx) => {
      return (
        <div
          key={`${tag.name}_${idx}`}
          className={selectedTags?.some(selectedTag =>
            tag.id === selectedTag.id) ? classes.tagsSelected : classes.tags}
          onClick={() => handleSelectedTags(tag)}
        >
          <span>#{tag.name}</span>
        </div>
      );
    });
  }

  return (
    <div className={classes.tagsContainer}>
      {!!visible && renderTagsOptions()}
    </div>
  );
}
