import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {Button} from "@material-ui/core";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
    width: 800,
    height: '95vh',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    '& h2': {
      textTransform: 'uppercase',
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },

    '& > div': {
      marginBottom: 20,
      width: '44%',
      marginRight: '40px',
    },
  },
  '.rs-uploader': {
    width: '100% !important',
  },
  createButton: {
    position: 'absolute',
    bottom: '10px',
    backgroundColor: 'rgb(80,129,115)',
    color: '#FFF',

    '&:hover': {
      background: 'rgb(56,97,87)',
    },
  },
  createButtonSecondary: {
    marginLeft: '10px',
    backgroundColor: 'rgb(80,129,115)',
    color: '#FFF',
    position: 'absolute',
    bottom: '10px',
    left: '100px',

    '&:hover': {
      background: 'rgb(56,97,87)',
    },
  },
}));

export const SimpleModal = ({ isOpened, onClose, title = '', children, submit = () => {}, additionalAction = () => {}, submitButtonText, additionalButtonText }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      open={isOpened}
      onClose={onClose}
      disableAutoFocus="true"
      disableEnforceFocus="true"
    >
      <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">{title}</h2>
         {children}
        <Button variant="contained" onClick={submit} className={classes.createButton}>
          {submitButtonText}
        </Button>
        {additionalButtonText ? (
          <Button variant="contained" onClick={additionalAction} className={classes.createButtonSecondary}>
            {additionalButtonText}
          </Button>
        ) : null}
      </div>
    </Modal>
  );
}
