const getUsersActivityReportQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/reports/users/activity`,
}

const getUsersLanguageReportQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/reports/users/language`,
}

const getTagsNewestReportQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/reports/tags/newest`,
}

const getRecipesReportQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/reports/recipes`,
}

const getTopRecipesReportQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/reports/recipes/top`,
}

export {
  getUsersActivityReportQuery,
  getUsersLanguageReportQuery,
  getTagsNewestReportQuery,
  getRecipesReportQuery,
  getTopRecipesReportQuery,
};
