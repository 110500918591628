import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  getUsersActivityReport,
  getUsersLanguageReport,
  getTagsNewestReport,
  getRecipesReport,
  getTopRecipesReport,
} from '../../data/controllers';



const useStyles = makeStyles((theme) => ({
  contentContainer: {
    maxWidth: 1100,
    margin: '0 auto',
    marginTop: 100,
    marginBottom: 100,
  },
  reportsContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 40,
  },
  reportsContainerTitle: {
    width: '100%',
    fontWeight: 600,
    fontSize: 24,
    paddingBottom: 18,
  },
  reportBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  reportBox: {
    backgroundColor: `#fff`,
    width: 'auto',
    minWidth: 120,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    padding: '10px 20px',
    textAlign: 'center',
    marginRight: 20,
  },
  reportSmallBox: {
    backgroundColor: `#fff`,
    width: 'auto',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    padding: '6px 12px',
    textAlign: 'center',
    marginRight: 20,
    marginBottom: 10,
  },
  reportBoxLabel: {
    color: '#7d7d7d',
    fontSize: 16,
    paddingBottom: 4,
  },
  reportBoxValue: {
    color: '#000',
    fontSize: 24,
    textAlign: 'center',
  },
  reportList: {
    backgroundColor: `#fff`,
    width: 'auto',
    minWidth: 420,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    padding: '10px 20px',
  },
  reportListRow: {
    display: 'flex',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  reportListRowCell: {
    padding: '10px 20px',
    marginRight: 40,
  },
  reportListHeadCell: {
    padding: '10px 20px',
    fontWeight: 600,
    marginRight: 40,
  },
}));


export const Reports = () => {
  const classes = useStyles();
  const [report, setReport] = useState({});

  useEffect(async() => {
    const { data: usersActivity } = await getUsersActivityReport();
    const { data: usersLanguage } = await getUsersLanguageReport();
    const { data: tagsNewest } = await getTagsNewestReport();
    const { data: recipes } = await getRecipesReport();
    const { data: topRecipes } = await getTopRecipesReport();

    setReport({
      usersActivity: usersActivity.users,
      usersLanguage: usersLanguage.users,
      tagsNewest: tagsNewest.tags,
      recipes: recipes.recipes,
      topRecipes: topRecipes.recipes,
    });
  }, []);

  const renderReportBox = (label, value) => {
    return (
      <div className={classes.reportBox}>
        <div className={classes.reportBoxLabel}>{label}</div>
        <div className={classes.reportBoxValue}>{value}</div>
      </div>
    );
  }

  const renderReportSmallBox = value => {
    return (
      <div className={classes.reportSmallBox}>{value}</div>
    );
  };

  const renderRecipesList = rows => {
    return (
      <div className={classes.reportList}>
        <div className={classes.reportListRow}>
          <div className={classes.reportListHeadCell} style={{ width: 120 }}>Count views</div>
          <div className={classes.reportListHeadCell} style={{ width: 420 }}>Recipe title</div>
          <div className={classes.reportListHeadCell}>Author</div>
        </div>
        {rows.map(row => {
          return (
            <div className={classes.reportListRow}>
              <div
                className={classes.reportListRowCell}
                style={{ width: 120, textAlign: 'right', fontWeight: 600 }}
              >{row.count_views}</div>
              <div className={classes.reportListRowCell} style={{ width: 420 }}>{row.recipe_title}</div>
              <div className={classes.reportListRowCell}>{row.author_nick}</div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <Fragment>
      {report.usersActivity ? (
        <div className={classes.reportsContainer}>
          <div className={classes.reportsContainerTitle}>Users activity</div>
          <div className={classes.reportBoxContainer}>
            {renderReportBox('Total users', report.usersActivity.total)}
            {renderReportBox('Active last week', report.usersActivity.active.last_week)}
            {renderReportBox('Active last month', report.usersActivity.active.last_month)}
            {renderReportBox('Not active more than month', report.usersActivity.not_active_more_than_month)}
            {renderReportBox('Deleted', report.usersActivity.deleted)}
          </div>
        </div>
      ) : null}


      {report.usersLanguage ? (
        <div className={classes.reportsContainer}>
          <div className={classes.reportsContainerTitle}>Users languages</div>
          <div className={classes.reportBoxContainer}>
            {Object.keys(report.usersLanguage).map(key => {
              return renderReportBox(key.toUpperCase(), report.usersLanguage[key]);
            })}
          </div>
        </div>
      ) : null}


      {report.recipes ? (
        <div className={classes.reportsContainer}>
          <div className={classes.reportsContainerTitle}>Recipes</div>
          <div className={classes.reportBoxContainer}>
            {renderReportBox('Total recipes', report.recipes.total)}
            {renderReportBox('New', report.recipes.new)}
            {renderReportBox('Published', report.recipes.published)}
            {renderReportBox('Private', report.recipes.private)}
            {renderReportBox('Drafts', report.recipes.drafts)}
            {renderReportBox('Copied', report.recipes.copied)}
            {renderReportBox('Viewed', report.recipes.views)}
            {renderReportBox('Liked', report.recipes.likes)}
            {renderReportBox('Commented', report.recipes.comments)}
          </div>
        </div>
      ) : null}


      {report.tagsNewest ? (
        <div className={classes.reportsContainer}>
          <div className={classes.reportsContainerTitle}>Newest tags</div>
          <div className={classes.reportBoxContainer}>
            {Object.values(report.tagsNewest).map(tag => {
              return renderReportSmallBox(tag.tag_name);
            })}
          </div>
        </div>
      ) : null}


      {report.topRecipes ? (
        <div className={classes.reportsContainer}>
          <div className={classes.reportsContainerTitle}>Top viewed recipes</div>
          <div className={classes.reportBoxContainer}>
            {renderRecipesList(report.topRecipes)}
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}
