import executeQuery from '../../data/api';
import {
  assignTagCategoryQuery,
  createNewTagCategoryQuery,
  changeTagAcitivityQuery,
  createNewTagQuery,
  deleteTagQuery,
  editTagQuery,
  getTagsQuery,
  getTagsCategoriesQuery,
  saveTagsQuery,
} from '../queries';


const getTagsData = async() => {
  try {
    return executeQuery(getTagsQuery);

  } catch(error) {
    return Promise.reject(error);
  }
};

const assignTagCategory = async body => {
  try {
    return executeQuery(assignTagCategoryQuery, { body });
  } catch (error) {
    return Promise.reject(error);
  }
}

const getTagsCategoriesData = async() => {
  try {
    return executeQuery(getTagsCategoriesQuery);
  } catch(error) {
    return Promise.reject(error);
  }
};

const saveTagsData = async body => {
  try {
    return executeQuery(saveTagsQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

const createNewTagData = async body => {
  try {
    return executeQuery(createNewTagQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

const createNewTagCategoryData = async body => {
  try {
    return executeQuery(createNewTagCategoryQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
}

const deleteTagData = async body => {
  try {
    return executeQuery(deleteTagQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

const editTagData = async body => {
  try {
    return executeQuery(editTagQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

const changeTagActivityData = async body => {
  try {
    return executeQuery(changeTagAcitivityQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

export {
  assignTagCategory,
  changeTagActivityData,
  createNewTagData,
  createNewTagCategoryData,
  deleteTagData,
  editTagData,
  getTagsData,
  getTagsCategoriesData,
  saveTagsData,
};
