import executeQuery from '../../data/api';
import {
  getUsersActivityReportQuery,
  getUsersLanguageReportQuery,
  getTagsNewestReportQuery,
  getRecipesReportQuery,
  getTopRecipesReportQuery,
} from '../queries';


const getUsersActivityReport = async() => {
  try {
    return executeQuery(getUsersActivityReportQuery);

  } catch(error) {
    return Promise.reject(error);
  }
};

const getUsersLanguageReport = async() => {
  try {
    return executeQuery(getUsersLanguageReportQuery);
  } catch (error) {
    return Promise.reject(error);
  }
}

const getTagsNewestReport = async() => {
  try {
    return executeQuery(getTagsNewestReportQuery);
  } catch(error) {
    return Promise.reject(error);
  }
};

const getRecipesReport = async() => {
  try {
    return executeQuery(getRecipesReportQuery);
  } catch(error) {
    return Promise.reject(error);
  }
};

const getTopRecipesReport = async() => {
  try {
    return executeQuery(getTopRecipesReportQuery);
  } catch(error) {
    return Promise.reject(error);
  }
};


export {
  getUsersActivityReport,
  getUsersLanguageReport,
  getTagsNewestReport,
  getRecipesReport,
  getTopRecipesReport,
};
