import React, { useEffect, useRef, useState } from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add, AddCircleOutline } from '@material-ui/icons';

import { SimpleModal, RegularList } from '../../components';
import { Icon, Uploader } from 'rsuite';
import {
  getFoodProductsData,
  changeFoodProductActivityData,
  deleteFoodProductData,
  editFoodProductData,
  getFoodProvidersData,
  createNewFoodProductData,
  getFoodStoresData,
} from '../../data/controllers';

import { useStyles } from './local.style';


const MANDATORY_FIELDS = ['name', 'description', 'short_description', 'ingredients'];



export const FoodProducts = () => {
  const [foodProducts, setFoodProducts] = useState([]);
  const [newFoodProductModalVisibility, setNewFoodProductModalVisibility] = useState(false);
  const [newFoodProductData, setNewFoodProductData] = useState({});
  const [error, setError] = useState(null);
  const [uploadValue, setUploadValue] = useState([]);
  const [dataInsertId, setDataInsertId] = useState(null);
  const [editableFields, setEditableFields] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState([]);
  const [foodProviders, setFoodProviders] = useState([]);
  const [nutritionalValuesRows, setnutritionalValuesRows] = useState([]);
  const [selectednutritionalValues, setSelectednutritionalValues] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [foodStores, setFoodStores] = useState([]);
  const refUploader = useRef(null);

  const classes = useStyles();

  const getFoodProducts = async () => {
    const { data: { foodProducts } } = await getFoodProductsData();
    await setFoodProducts(foodProducts);
  };

  const getFoodProviders = async () => {
    const { data: { foodProviders } } = await getFoodProvidersData();
    await setFoodProviders(foodProviders);
  };


  const getFoodStores = async () => {
    const { data: { foodStores } } = await getFoodStoresData();
    await setFoodStores(foodStores);
  };

  useEffect(() => {
    getFoodProducts();
    getFoodProviders();
    getFoodStores();
  }, []);

  useEffect(async() => {
    const isFormComplete = checkFormCompleteness();

    if(!!dataInsertId && isFormComplete) {
      refUploader.current.start();
      setNewFoodProductModalVisibility(false);
      await getFoodProducts();
    }
  }, [
    dataInsertId,
  ]);

  const checkFormCompleteness = () => {
    return MANDATORY_FIELDS.every(field => {
      return Object.keys(newFoodProductData).includes(field);
    });
  };


  const createNewFoodProduct = async() => {
    const isFormComplete = checkFormCompleteness();
    setError(null);

    const productData = {
      ...newFoodProductData,
      food_provider_id: selectedProvider,
      nutritionalValues: Object.values(selectednutritionalValues),
      storesIds: selectedStores,
    }

    if(isFormComplete && uploadValue.length) {
      const insertId = await createNewFoodProductData({ foodProductData: productData });
      setDataInsertId(insertId);
      setError(null);
    }  else if (isFormComplete && !uploadValue.length) {
      setError('You need to add product picture');
    } else if(!isFormComplete) {
      setError(`Fields ${MANDATORY_FIELDS.join(', ')} are mandatory`);
    }
  };

  const handleFoodProductActivityChange = async (e, id) => {
    await changeFoodProductActivityData({
      id,
      active: e.target.checked,
    });

    await getFoodProducts();
  };

  const handleUpdateFoodProduct = async tag => {
    const updatedFoodProduct = foodProducts.map(product => {
      if(product.id === tag.id) {
        return {
          ...product,
          editable: true,
        };
      }

      return {
        ...product,
        editable: false,
      }
    });

    setEditableFields(updatedFoodProduct);
  }

  const handleEditKeyPress = async (e, product, index) => {
    if(e.key === 'Enter'){
      await editFoodProductData({
        id: product.id,
        name: e.target.value,
      });

      editableFields[index] = {
        ...product,
        editable: false,
      };

      setEditableFields(editableFields);
      await getFoodProducts();
    }
  }

  const renderProvidersOptions = () => {
    return foodProviders.map(provider => {
      return <MenuItem value={provider.id}>{provider.name}</MenuItem>;
    });
  };

  const renderStoresOptions = () => {
    return foodStores.map(store => {
      return <MenuItem value={store.id}>{store.name}</MenuItem>;
    });
  };

  const handleNewFoodProductData = ({ target: { id: inputId, value }}) => {
    let ingredientsArr;

    if(inputId === 'ingredients') {
      ingredientsArr = value.split(',');
    }

    setNewFoodProductData({
      ...newFoodProductData,
      [inputId]: value,
      ingredients: ingredientsArr,
    });
  };

  const handleUploadChange = value => {
    if (!!value) setError(null);
    setUploadValue([ ...value ]);
  };

  const handleDeleteFoodProduct = async id => {
    await deleteFoodProductData({ id });
    await getFoodProducts();
  };

  const handleProviderChange = e => {
    setSelectedProvider(e.target.value);
  };

  const handleStoreChange = e => {
    setSelectedStores(e.target.value);
  };

  const addnutritionalValuesRow = () => {
    setnutritionalValuesRows([
      ...nutritionalValuesRows,
      generatenutritionalValuesRow(),
    ]);
  };

  const handlenutritionalValueChange = ({ target: { id, value } }, index) => {
    setSelectednutritionalValues({
      ...selectednutritionalValues,
      [index]: {
        ...selectednutritionalValues[index],
        [id]: value,
      },
    });
  };

  const generatenutritionalValuesRow = index => {
    return (
      <div className={classes.nutritionalValuesRow}>
        <TextField
          label="name"
          id="nutritionalValueName"
          onChange={e => handlenutritionalValueChange(e, index)}
        />
        <TextField
          label="amount"
          id="nutritionalValueAmount"
          onChange={e => handlenutritionalValueChange(e, index)}
        />
        {(index === nutritionalValuesRows.length - 1) ? <AddCircleOutline onClick={addnutritionalValuesRow}/> : null}
      </div>
    );
  };

  useEffect(() => {
    setnutritionalValuesRows([
      ...nutritionalValuesRows,
      generatenutritionalValuesRow(0),
    ]);
  }, []);

  return (
    <div className={classes.contentContainer}>
      <SimpleModal
        isOpened={newFoodProductModalVisibility}
        onClose={() => setNewFoodProductModalVisibility(false)}
        title="Create new food product"
        submit={createNewFoodProduct}
        submitButtonText="Add food product"
      >
        <div className={classes.modalContentContainer}>
          <div className={classes.column}>
            <TextField
              label="name"
              id="name"
              onChange={handleNewFoodProductData}
            />
            <TextField
              label="description"
              id="description"
              onChange={handleNewFoodProductData}
              multiline={true}
            />
            <div className={classes.nutritionalValuesContainer}>
              <h6>Nutritional Values</h6>
              {nutritionalValuesRows.map((row, index) => {
                return generatenutritionalValuesRow(index);
              })}
            </div>
          </div>
          <div className={classes.column}>
            <TextField
              label="short description"
              id="short_description"
              onChange={handleNewFoodProductData}
            />
            <TextField
              label="ingredients - ing1,ing2,ing3..."
              id="ingredients"
              onChange={handleNewFoodProductData}
            />

            <div className={classes.optionsContainer}>
              <FormControl className={classes.formControl}>
                <InputLabel id="provider">Provider</InputLabel>
                <Select
                  labelId="provider"
                  value={selectedProvider}
                  onChange={handleProviderChange}
                >
                  {renderProvidersOptions()}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id="store">Stores</InputLabel>
                <Select
                  multiple
                  labelId="store"
                  value={selectedStores}
                  onChange={handleStoreChange}
                >
                  {renderStoresOptions()}
                </Select>
              </FormControl>
            </div>
            {!!error ? <p style={{ color: 'red' }}>{error}</p> : null}
            <Uploader
              action={`${process.env.REACT_APP_API_BASE_URL}/admin/upload`}
              onChange={handleUploadChange}
              autoUpload={false}
              listType="picture-text"
              fileList={uploadValue}
              ref={refUploader}
              withCredentials={true}
              data={{ context: 'food_products', id: dataInsertId }}
            >
              <button>
                <Icon icon='camera-retro' size="lg" />
              </button>
            </Uploader>
          </div>
        </div>
      </SimpleModal>
      <div className={classes.contentContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <Typography
              variant="h6"
              onClick={() => setNewFoodProductModalVisibility(true)}
              className={classes.addTagButton}
            >
              <Add />FOOD PRODUCT
            </Typography>
          </div>
        </div>
        <div className={classes.listsWrapper}>
          <h3>FOOD PRODUCTS</h3>
          <div className={classes.list}>
            <RegularList
              items={foodProducts}
              handleItemActivityChange={handleFoodProductActivityChange}
              handleUpdateItem={handleUpdateFoodProduct}
              handleDeleteItem={handleDeleteFoodProduct}
              handleEditKeyPress={handleEditKeyPress}
              editableFields={editableFields}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
