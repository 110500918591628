import React, { useEffect, useState, useRef } from 'react';

import {
  TextField,
  Typography,
} from '@material-ui/core';
import { Uploader, Icon } from 'rsuite';
import { Add } from '@material-ui/icons';

import { SimpleModal, RegularList } from '../../components';
import {
  getFoodProvidersData,
  changeFoodProviderActivityData,
  deleteFoodProviderData,
  editFoodProviderData,
  createNewFoodProviderData,
} from '../../data/controllers/local';

import { useStyles } from './local.style';

const MANDATORY_FIELDS = ['name', 'description', 'short_description', 'history'];


export const FoodProviders = () => {
  const [foodProviders, setFoodProviders] = useState([]);
  const [newFoodProviderModalVisibility, setNewFoodProviderModalVisibility] = useState(false);
  const [newFoodProviderData, setNewFoodProviderData] = useState({});
  const [editableFields, setEditableFields] = useState([]);
  const [uploadValue, setUploadValue] = useState([]);
  const [dataInsertId, setDataInsertId] = useState(null);
  const [error, setError] = useState(null);
  const refUploader = useRef(null);

  const classes = useStyles();

  const getFoodProviders = async () => {
    const { data: { foodProviders } } = await getFoodProvidersData();
    await setFoodProviders(foodProviders);
  }

  const checkFormCompleteness = () => {
    return MANDATORY_FIELDS.every(field => {
      return Object.keys(newFoodProviderData).includes(field);
    });
  };

  useEffect(() => {
    getFoodProviders();
  }, []);


  useEffect(async() => {
   const isFormComplete = checkFormCompleteness();

    if(!!dataInsertId && isFormComplete) {
      refUploader.current.start();
      setNewFoodProviderModalVisibility(false);
      await getFoodProviders();
    }
  }, [
    dataInsertId,
  ]);

  const createNewFoodProvider = async() => {
    const isFormComplete = checkFormCompleteness();
    setError(null);

    if(isFormComplete && uploadValue.length) {
      const insertId = await createNewFoodProviderData({ foodProviderData: newFoodProviderData });
      setDataInsertId(insertId);
      setError(null);
    } else if (isFormComplete && !uploadValue.length) {
      setError('You need to add provider picture');
    } else if(!isFormComplete) {
      setError(`Fields ${MANDATORY_FIELDS.join(', ')} are mandatory`);
    }
  };

  const handleFoodProviderActivityChange = async (e, id) => {
    await changeFoodProviderActivityData({
      id,
      active: e.target.checked,
    });

    await getFoodProviders();
  };

  const handleUpdateFoodProvider = async tag => {
    const updatedFoodProvider = foodProviders.map(Provider => {
      if(Provider.id === tag.id) {
        return {
          ...Provider,
          editable: true,
        };
      }

      return {
        ...Provider,
        editable: false,
      }
    });

    setEditableFields(updatedFoodProvider);
  }

  const handleEditKeyPress = async (e, Provider, index) => {
    if(e.key === 'Enter'){
      await editFoodProviderData({
        id: Provider.id,
        name: e.target.value,
      });

      editableFields[index] = {
        ...Provider,
        editable: false,
      };

      setEditableFields(editableFields);
      await getFoodProviders();
    }
  };


  const handleDeleteFoodProvider = async id => {
    await deleteFoodProviderData({ id });
    await getFoodProviders();
  };

  const handleNewFoodProviderData = ({ target: { id: inputId, value }}) => {
    setNewFoodProviderData({
      ...newFoodProviderData,
      [inputId]: value,
    });
  };

  const handleUploadChange = value => {
    if (!!value) setError(null);
    setUploadValue([ ...value ]);
  };

  return (
    <div className={classes.contentContainer}>
      <SimpleModal
        isOpened={newFoodProviderModalVisibility}
        onClose={() => setNewFoodProviderModalVisibility(false)}
        title="Create new food Provider"
        submit={createNewFoodProvider}
        submitButtonText="Add food Provider"
      >
        <TextField
          label="name"
          id="name"
          onChange={handleNewFoodProviderData}
        />
        <TextField
          label="short decription"
          id="short_description"
          onChange={handleNewFoodProviderData}
          multiline={true}
        />
        <TextField
          label="description"
          id="description"
          onChange={handleNewFoodProviderData}
          multiline={true}
        />
        <TextField
          label="history"
          id="history"
          onChange={handleNewFoodProviderData}
          multiline={true}
        />
        <TextField
          label="instagram url"
          id="instagram_url"
          type="url"
          onChange={handleNewFoodProviderData}
        />
        <TextField
          label="twitter url"
          id="twitter_url"
          type="url"
          onChange={handleNewFoodProviderData}
        />
        <TextField
          label="facebook url"
          id="facebook_url"
          type="url"
          onChange={handleNewFoodProviderData}
        />
        <TextField
          label="website url"
          id="website_url"
          type="url"
          onChange={handleNewFoodProviderData}
        />
        {!!error ? <p style={{ color: 'red' }}>{error}</p> : null}
        <Uploader
          action={`${process.env.REACT_APP_API_BASE_URL}/admin/upload`}
          onChange={handleUploadChange}
          autoUpload={false}
          listType="picture-text"
          fileList={uploadValue}
          ref={refUploader}
          withCredentials={true}
          data={{ context: 'food_providers', id: dataInsertId }}
        >
          <button>
            <Icon icon='camera-retro' size="lg" />
          </button>
        </Uploader>
      </SimpleModal>
      <div className={classes.contentContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <Typography
              variant="h6"
              onClick={() => setNewFoodProviderModalVisibility(true)}
              className={classes.addTagButton}
            >
              <Add />FOOD PROVIDER
            </Typography>
          </div>
        </div>
        <div className={classes.listsWrapper}>
          <h3>FOOD PROVIDERS</h3>
          <div className={classes.list}>
            <RegularList
              items={foodProviders}
              handleItemActivityChange={handleFoodProviderActivityChange}
              handleUpdateItem={handleUpdateFoodProvider}
              handleDeleteItem={handleDeleteFoodProvider}
              handleEditKeyPress={handleEditKeyPress}
              editableFields={editableFields}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
