const RECIPES_STATUS_OPTIONS = [{
  id: 1,
  name: 'pending',
}, {
  id: 2,
  name: 'accepted',
}, {
  id: 3,
  name: 'rejected',
}];

const RECIPES_LANG_OPTIONS = [{
  id: 1,
  name: 'PL',
}, {
  id: 2,
  name: 'NO',
}, {
  id: 3,
  name: 'EN',
}];

const MODERATION_ACTIONS = [
  {
    type: 'accepted',
    color: 'rgb(80,129,115)',
  },
  {
    type: 'rejected',
    color: '#E45C45',
  },
  {
    type: 'pending',
    color: 'rgb(139,158,162)',
  },
];

export {
  RECIPES_STATUS_OPTIONS,
  RECIPES_LANG_OPTIONS,
  MODERATION_ACTIONS,
};
