import { makeStyles } from '@material-ui/core/styles';


export const useStyles =  makeStyles((theme) => ({
  contentContainer: {
    maxWidth: 1000,
    margin: '0 auto',
    marginTop: 30,
  },
  list: {
    display: 'flex',

    '& p': {
      marginRight: '10px',
    }
  },
  rangePicker: {
    width: 280,
    marginBottom: 50,

    '& .rs-picker-toggle-clean': {
      display: 'none',
    },

    '& .rs-picker-toggle-value': {
      color: 'rgba(80,129,115) !important',
    },
  },
  listRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '2px',
    height: '50px',
    padding: '0 20px',
    borderRadius: '6px',
    marginTop: 25,

    '&:hover': {
      backgroundColor: 'rgba(80,129,115,0.09)',
    },
  },
  commandsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'uppercase',
    padding: '0 20px',
    width: 1000,
    fontSize: 20,
    borderBottom: '1px solid rgba(80,129,115,0.2)',

    '& p': {
      margin: '0 0 10px 0',
    },
  },
  commentColumn: {
    width: 200,
  },
  sentimentColumn: {
    width: 200,
  },
  actionsColumn: {
    width: 300,
  },
  dateColumn: {
    width: 150,
  },
  actionItem: {
    marginRight: 10,
    color: '#b9b8b8',
  },
}));
