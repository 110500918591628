import React, { useEffect, useState, useRef, Fragment } from 'react';

import omit from 'lodash.omit';
import {
  MenuItem,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import Switch from '@mui/material/Switch';
import { Add } from '@material-ui/icons';

import { SimpleModal, RegularList } from '../../components';
import {
  getPlacesData,
  requestNewPlaceData,
  createNewPlaceData,
  deletePlaceData,
  editPlaceData,
} from '../../data/controllers/local';

import { WEEKDAYS, COUNTRIES } from '../../constants';

import { useStyles } from './local.style';
import { Icon, Uploader } from 'rsuite';

const PHISICAL_SHOP_FIELDS_ONLY = ['address', 'city', 'latitude', 'longitude', 'map_url', 'opening_hours', 'place_id'];


const previewFile = (file, callback) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}


export const Places = () => {
  const [places, setPlaces] = useState([]);
  const [newPlace, setNewPlace] = useState({ opening_hours: WEEKDAYS.map(day => [null, null]) });
  const [newPlaceModalVisibility, setNewPlaceModalVisibility] = useState(false);
  const [placeId, setPlaceId] = useState(null);
  const [error, setError] = useState(null);
  const [isInternetShop, setIsInternetShop] = useState(false);
  const [uploadValue, setUploadValue] = useState([]);
  const [dataInsertId, setDataInsertId] = useState(null);
  const [country, setCountry] = useState('no');
  const [isEditMode, setIsEditMode] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const refUploader = useRef(null);

  const classes = useStyles();

  const getPlaces = async country => {
    const { data: { places } }  = await getPlacesData(country);
    await setPlaces(places);
  }

  useEffect(() => {
    getPlaces('no');
  }, []);

  useEffect(() => {
    getPlaces(country);
  }, [
    country,
  ]);

  useEffect(async() => {
    if(!!dataInsertId) {
      refUploader.current.start();
    }
  }, [
    dataInsertId,
  ]);

  const handleRegularFieldChange = ({ target: { id: inputId, value }}) => {
    setNewPlace({
      ...newPlace,
      [inputId]: value,
    });
  }

  const handleNewPlaceId = ({ target: { value }}) => {
    setPlaceId(value);
  };

  const findNewPlace = async () => {
    const place = await requestNewPlaceData(placeId)
    setNewPlace(place);
  }

  const savePlace = async () => {
    const unifyOpeningHoursFormat = newPlace?.opening_hours.map(hourArray => [
      !!hourArray[0] ? hourArray[0].replace(":", "") : null,
      !!hourArray[1] ? hourArray[1].replace(":", "") : null,
    ]);

    const newPlaceWithUnifiedOpeningHoursFormat = {
      ...newPlace,
      opening_hours: unifyOpeningHoursFormat,
    }

    const dataFormattedBasedOnStoreType = isInternetShop ? omit(newPlace, PHISICAL_SHOP_FIELDS_ONLY) : newPlace;
    const insertId = isEditMode ? await editPlaceData(dataFormattedBasedOnStoreType) : await createNewPlaceData(dataFormattedBasedOnStoreType);

    if(uploadValue.length) {
      setDataInsertId(insertId);
    }

    setNewPlaceModalVisibility(false);
    getPlaces(country);
    setNewPlace({ opening_hours: WEEKDAYS.map(day => [null, null]) });
    setIsInternetShop(false);
  }

  const handleOpeningHoursChange = (end, index, { target: { value }}) => {    
    const modifiedOpeningHours = newPlace.opening_hours.map((dayArray, idx) => {
      const endIndex = end === "from" ? 0 : 1;

      if(index === idx) {
        dayArray[endIndex] = value;
      }

      return dayArray;
    });

    setNewPlace({
      ...newPlace,
      opening_hours: modifiedOpeningHours,
    });
  }

  const renderOpeningHours = () => {
    return WEEKDAYS.map((day, index) => {
      return (
        <div>
          <div className={classes.openingHoursPerDayContainer}>
            <TextField
              helperText={`${WEEKDAYS[index]} from`}
              size="small"
              className={classes.fromToField}
              id={`from-${index}`}
              onChange={e => handleOpeningHoursChange("from", index, e)}
              value={!!newPlace.opening_hours ? newPlace.opening_hours[index][0] : ""}
              />
            <TextField
              helperText="to"
              size="small"
              className={classes.fromToField}
              id={`to-${index}`}
              onChange={e => handleOpeningHoursChange("to", index, e)}
              value={!!newPlace.opening_hours ? newPlace.opening_hours[index][1] : ""}
            />
          </div>
        </div>
      );
    })
  }

  const handleDeletePlace = async id => {
    await deletePlaceData({ id });
    await getPlaces(country);
  };

  const handleSwitchChange = e => {
    setNewPlace({
      ...newPlace,
      is_online_shop: e.target.checked,
      is_physical_shop: !e.target.checked,
    })
    setIsInternetShop(e.target.checked);
  }

  const handleUploadChange = value => {
    if (!!value) setError(null);
    setUploadValue([ ...value ]);
    
    const imageValue = value[value.length - 1];

    previewFile(imageValue.blobFile, imageValue => {
      setFileInfo(imageValue);
    });
  };

  const renderCountriesOptions = () => {
    return COUNTRIES.map(({ name, shortcut }, idx) => {
      return <MenuItem
        key={`${shortcut}_${idx}`}
        value={shortcut}
      >
        {name}
      </MenuItem>
    });
  }

  const handleUpdateItem = async item => {
    setIsEditMode(true);
    setNewPlaceModalVisibility(true);
    setNewPlace(item);
  }

  return (
    <div className={classes.contentContainer}>
      <SimpleModal
        isOpened={newPlaceModalVisibility}
        onClose={() => setNewPlaceModalVisibility(false)}
        title={isEditMode ? "Edit place" : "Add new place"}
        submit={savePlace}
        submitButtonText="Save"
        additionalAction={findNewPlace}
        additionalButtonText={isInternetShop || isEditMode ? null : "Find place"}
      >
        <div className={classes.modalContentContainer}>
          <div className={classes.column}>
            <div className={classes.switchConteiner}>
              <Switch  
                checked={isInternetShop}
                onChange={handleSwitchChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <p>Is Online shop?</p>
            </div>
            {
              (isInternetShop || isEditMode) ? null : (
                <TextField
                  label="place id"
                  className={classes.textField}
                  id="placeId"
                  onChange={handleNewPlaceId}
                />
              )
            }
            <TextField
              label="name"
              className={classes.textField}
              id="name"
              size="small"
              onChange={handleRegularFieldChange}
              value={newPlace.name || ""}
            />
            <TextField
              label="description"
              id="description"
              size="small"
              className={classes.textField}
              onChange={handleRegularFieldChange}
              value={newPlace.description || ""}
            />
            <TextField
              label="country code (pl, no, etc.)"
              id="country"
              size="small"
              className={classes.textField}
              onChange={handleRegularFieldChange}
              value={newPlace.country || ""}
            />
            {!isInternetShop ? (
              <Fragment>
                <TextField
                  label="address"
                  id="address"
                  size="small"
                  className={classes.textField}
                  onChange={handleRegularFieldChange}
                  value={newPlace.address || ""}
                />
                <TextField
                  label="city"
                  id="city"
                  size="small"
                  className={classes.textField}
                  onChange={handleRegularFieldChange}
                  value={newPlace.city || ""}
                />
                <TextField
                  label="latitude"
                  id="latitude"
                  size="small"
                  className={classes.textField}
                  onChange={handleRegularFieldChange}
                  value={newPlace.latitude || ""}
                />
                <TextField
                  label="longitude"
                  id="longitude"
                  size="small"
                  className={classes.textField}
                  onChange={handleRegularFieldChange}
                  value={newPlace.longitude || ""}
                />
                <TextField
                  label="mapURL"
                  id="map_url"
                  size="small"
                  className={classes.textField}
                  onChange={handleRegularFieldChange}
                  value={newPlace.map_url || ""}
                />
              </Fragment>
            ): null}
            <TextField
              label="phone"
              id="phone"
              className={classes.textField}
              size="small"
              onChange={handleRegularFieldChange}
              value={newPlace.phone || ""}
            />
          </div>
          <div className={classes.column}>
            <TextField
              label="rating"
              id="rating"
              size="small"
              className={classes.textField}
              onChange={handleRegularFieldChange}
              value={newPlace.rating || ""}
            />
            <TextField
              label="website"
              id="website"
              size="small"
              className={classes.textField}
              onChange={handleRegularFieldChange}
              value={newPlace.website || ""}
            />
            {!isInternetShop ? (
              <div className={classes.openingHoursContainer}>
                <p>Opening hours:</p>
                {renderOpeningHours()}
              </div>
            ) : null}
            <div className={classes.uploaderContainer}>
              <Uploader
                action={`${process.env.REACT_APP_API_BASE_URL}/admin/upload`}
                fileListVisible={false}
                onChange={handleUploadChange}
                multiple={false}
                autoUpload={false}
                listType="picture"
                fileList={uploadValue}
                ref={refUploader}
                withCredentials={true}
                data={{ context: 'places', id: dataInsertId }}
              >
                <button style={{ width: 80, height: 80 }}>
                  {fileInfo ? (
                    <img src={fileInfo} width="100%" height="100%" />
                  ) : !!newPlace.original_picture_url  ? (
                    <img src={newPlace.original_picture_url} width="100%" height="100%" />
                  ) : (
                    <Icon icon='camera-retro' size="lg" />
                  )}
                </button>
              </Uploader>
            </div>
          </div>
        </div>
        {!!error ? <p style={{ color: 'red' }}>{error}</p> : null}
      </SimpleModal>
      <div className={classes.contentContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.placesHeader}>
            <h3>PLACES</h3>
            <Typography
              variant="h6"
              onClick={() => setNewPlaceModalVisibility(true)}
              className={classes.addTagButton}
            >
              <Add />Place
            </Typography>
          </div>
        </div>
        <div>
          <FormControl>
            <InputLabel id="country">Country</InputLabel>
            <Select
              labelId="country"
              value={country}
              onChange={e => setCountry(e.target.value)}
            >
              {renderCountriesOptions()}
            </Select>
          </FormControl>
        </div>
        {places.length ? (
          <div className={classes.listsWrapper}>
            <div className={classes.list}>
              <RegularList
                items={places}
                areItemsEditable={true}
                areItemsActivable={false}
                handleDeleteItem={handleDeletePlace}
                handleUpdateItem={handleUpdateItem}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
