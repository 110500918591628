import axios from 'axios';
import history from './../history';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;


axios.interceptors.response.use(null, error => {
  if(error?.response?.status === 403) {
    window.location.replace('/');
  }

  return Promise.reject(error);
});


const executeQuery = (query, { body, params } = {}) => {
  let endpointUrl;
  if(params && Object.keys(params).length) {
    endpointUrl = Object.keys(params).reduce((accumulator, currentValue, idx) => {
      let currentQuery = !accumulator ? query.url : accumulator;
      return currentQuery.replace(`:${currentValue}`, params[currentValue]);
    }, '');
  }

  const config = {
    ...query,
    url: endpointUrl || query.url,
  };

  switch (query.method) {
    case 'GET':
      config.params = body;
      break;

    case 'PATCH':
    case 'POST':
    case 'PUT':
    case 'DELETE':
      config.body = body;
      config.data = body;
      break;

    default:
      config.body = JSON.stringify(body);
      break;
  }

  return axios(config).catch(error => Promise.reject(error));
};

export default executeQuery;
