import React, { useEffect, useRef, useState } from 'react';

import {
  TextField,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { SimpleModal, RegularList } from '../../components';
import {
  getFoodStoresData,
  changeFoodStoreActivityData,
  deleteFoodStoreData,
  editFoodStoreData,
  createNewFoodStoreData,
  getFoodProvidersData,
} from '../../data/controllers/local';

import { useStyles } from './local.style';
import { Icon, Uploader } from 'rsuite';

const MANDATORY_FIELDS = ['name', 'description', 'address', 'city', 'zipcode', 'lat', 'lon'];



export const FoodStores = () => {
  const [foodStores, setFoodStores] = useState([]);
  const [newFoodStoreModalVisibility, setNewFoodStoreModalVisibility] = useState(false);
  const [newFoodStoreData, setNewFoodStoreData] = useState({});
  const [editableFields, setEditableFields] = useState([]);
  const [error, setError] = useState(null);
  const [uploadValue, setUploadValue] = useState([]);
  const [dataInsertId, setDataInsertId] = useState(null);
  const [foodProviders, setFoodProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const refUploader = useRef(null);
  const classes = useStyles();

  const getFoodStores = async () => {
    const { data: { foodStores } } = await getFoodStoresData();
    await setFoodStores(foodStores);
  }

  const checkFormCompleteness = () => {
    return MANDATORY_FIELDS.every(field => {
      return Object.keys(newFoodStoreData).includes(field);
    });
  };

  const getFoodProviders = async () => {
    const { data: { foodProviders } } = await getFoodProvidersData();
    await setFoodProviders(foodProviders);
  }

  useEffect(() => {
    getFoodStores();
    getFoodProviders();
  }, []);

  useEffect(async() => {
    const isFormComplete = checkFormCompleteness();

    if(!!dataInsertId && isFormComplete) {
      refUploader.current.start();
      setNewFoodStoreModalVisibility(false);
      await getFoodStores();
    }
  }, [
    dataInsertId,
  ]);



  const createNewFoodStore = async() => {
    const isFormComplete = checkFormCompleteness();
    setError(null);

    const storeData = {
      ...newFoodStoreData,
      food_provider_id: selectedProvider,
    }

    if(isFormComplete && uploadValue.length) {
      const insertId = await createNewFoodStoreData({ foodStoreData: storeData });
      setDataInsertId(insertId);
      setError(null);
    } else if (isFormComplete && !uploadValue.length) {
      setError('You need to add store picture');
    } else if(!isFormComplete && !selectedProvider) {
      setError(`Fields ${MANDATORY_FIELDS.join(', ')} are mandatory`);
    }
  };

  const handleFoodStoreActivityChange = async (e, id) => {
    await changeFoodStoreActivityData({
      id,
      active: e.target.checked,
    });

    await getFoodStores();
  };

  const handleUploadChange = value => {
    if (!!value) setError(null);
    setUploadValue([ ...value ]);
  };

  const handleNewFoodStoreData = ({ target: { id: inputId, value }}) => {
    setNewFoodStoreData({
      ...newFoodStoreData,
      [inputId]: value,
    });
  };

  const handleUpdateFoodStore = async tag => {
    const updatedFoodStore = foodStores.map(Store => {
      if(Store.id === tag.id) {
        return {
          ...Store,
          editable: true,
        };
      }

      return {
        ...Store,
        editable: false,
      }
    });

    setEditableFields(updatedFoodStore);
  }

  const handleEditKeyPress = async (e, Store, index) => {
    if(e.key === 'Enter'){
      await editFoodStoreData({
        id: Store.id,
        name: e.target.value,
      });

      editableFields[index] = {
        ...Store,
        editable: false,
      };

      setEditableFields(editableFields);
      await getFoodStores();
    }
  };

  const handleDeleteFoodStore = async id => {
    await deleteFoodStoreData({ id });
    await getFoodStores();
  };

  const renderProvidersOptions = () => {
    return foodProviders.map(provider => {
      return <MenuItem value={provider.id}>{provider.name}</MenuItem>;
    });
  }

  const handleProviderChange = e => {
    setSelectedProvider(e.target.value);
  }

  return (
    <div className={classes.contentContainer}>
      <SimpleModal
        isOpened={newFoodStoreModalVisibility}
        onClose={() => setNewFoodStoreModalVisibility(false)}
        title="Create new food store"
        submit={createNewFoodStore}
        submitButtonText="Add food store"
      >
        <TextField
          label="name"
          id="name"
          onChange={handleNewFoodStoreData}
        />
        <TextField
          label="description"
          id="description"
          onChange={handleNewFoodStoreData}
          multiline={true}
        />
        <TextField
          label="address"
          id="address"
          onChange={handleNewFoodStoreData}
        />
        <TextField
          label="city"
          id="city"
          onChange={handleNewFoodStoreData}
        />
        <TextField
          label="zipcode"
          id="zipcode"
          onChange={handleNewFoodStoreData}
        />
        <TextField
          label="website url"
          id="website_url"
          type="url"
          onChange={handleNewFoodStoreData}
        />
        <TextField
          label="Latitude"
          id="lat"
          onChange={handleNewFoodStoreData}
        />
        <TextField
          label="Longitude"
          id="lon"
          onChange={handleNewFoodStoreData}
        />
        <FormControl className={classes.formControl}>
          <InputLabel id="select-label">Provider</InputLabel>
          <Select
            labelId="select-label"
            id="demo-simple-select"
            value={selectedProvider}
            onChange={handleProviderChange}
          >
            {renderProvidersOptions()}
          </Select>
        </FormControl>
        {!!error ? <p style={{ color: 'red' }}>{error}</p> : null}
        <Uploader
          action={`${process.env.REACT_APP_API_BASE_URL}/admin/upload`}
          onChange={handleUploadChange}
          autoUpload={false}
          listType="picture-text"
          fileList={uploadValue}
          ref={refUploader}
          withCredentials={true}
          data={{ context: 'food_shops', id: dataInsertId }}
        >
          <button>
            <Icon icon='camera-retro' size="lg" />
          </button>
        </Uploader>
      </SimpleModal>
      <div className={classes.contentContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <Typography
              variant="h6"
              onClick={() => setNewFoodStoreModalVisibility(true)}
              className={classes.addTagButton}
            >
              <Add />FOOD STORE
            </Typography>
          </div>
        </div>
        <div className={classes.listsWrapper}>
          <h3>FOOD STORES</h3>
          <div className={classes.list}>
            <RegularList
              items={foodStores}
              handleItemActivityChange={handleFoodStoreActivityChange}
              handleUpdateItem={handleUpdateFoodStore}
              handleDeleteItem={handleDeleteFoodStore}
              handleEditKeyPress={handleEditKeyPress}
              editableFields={editableFields}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
