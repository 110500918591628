import React, { useEffect, useState } from 'react';
import {
  Checkbox, IconButton, List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip
} from "@material-ui/core";
import {Delete, Edit, Label} from "@material-ui/icons";

import { useStyles } from './regularList.style';



export const RegularList = ({
    items = [],
    handleEditKeyPress = () => {},
    handleItemActivityChange = () => {},
    handleUpdateItem = () => {},
    handleDeleteItem = () => {},
    editableFields = [],
    areItemsEditable = true,
    areItemsActivable = true,
    areItemsDeletable = true,
  }) => {
  const classes = useStyles();


  const renderListItems = () => {
    return (
      <List>
        {items.map((item, index) => (
          <ListItem
            ContainerComponent="li"
            key={`${item.name}_${index}`}
          >
            <ListItemIcon>
              <Label />
            </ListItemIcon>

            {
              editableFields[index]?.editable
                ? <TextField
                    className={classes.productTextField}
                    id="standard-basic"
                    onKeyPress={e => handleEditKeyPress(e, item, index)}
                    defaultValue={item.name}
                  />
                : <div className={classes.foodProductTextContainer}>
                    <ListItemText primary={item.name} secondary={item.description} className={classes.productItem}/>
                   </div>
            }

            <ListItemSecondaryAction className={classes.secondaryRoot}>
              {!!areItemsActivable ? (
                <Tooltip title={!!item.active ? 'Activated' : 'Disactivated'}>
                  <Checkbox
                    checked={!!item.active}
                    onChange={e => handleItemActivityChange(e, item.id)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    className={classes.checkbox}
                  />
                </Tooltip>
              ) : null}
              {!!areItemsEditable ? (
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleUpdateItem(item)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              ) : null}
              {!!areItemsDeletable ? (
                <Tooltip title="Remove">
                  <IconButton onClick={() => handleDeleteItem(item.id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              ) : null}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <div className={classes.tagsContainer}>
      {renderListItems()}
    </div>
  );
}
