import React, { useEffect, useState, Fragment, useRef } from 'react';

import { SimpleModal } from '../../components';
import {
  Button,
  Checkbox, Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, AddCircleOutline, Delete, ViewStream } from '@material-ui/icons';
import { Form } from 'react-final-form';

import { getFormFields } from './addEventFields';

import {
  createNewFoodProductData,
  deleteEventData,
  editEventData,
  getEventsData,
  getUsersData
} from '../../data/controllers';
import executeQuery from '../../data/api';
import { changeEventActivityQuery } from '../../data/queries';

import { useStyles } from './events.style';
import {Icon, Uploader} from "rsuite";


export const Events = () => {
  let submit;
  const classes = useStyles();
  const [newEventModalVisibility, setNewEventModalVisibility] = useState();
  const [editableFields, setEditableFields] = useState([]);
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]);
  const [uploadValue, setUploadValue] = useState([]);
  const [user, setUser] = useState([]);
  const [error, setError] = useState(null);
  const [dataInsertId, setDataInsertId] = useState(null);
  const [newEventValues, setNewEventValues] = useState({});
  const refUploader = useRef(null);



  useEffect(async() => {
    await getEvents();
    await getUsers();
  }, []);

  useEffect(async() => {
    // TO DO:
    const isFormComplete = true;

    if(!!dataInsertId && isFormComplete) {
      refUploader.current.start();
      setNewEventModalVisibility(false);
      await getEvents();
    }
  }, [
    dataInsertId,
  ]);

  const getUsers = async() => {
    const { data: { users } } = await getUsersData();

    setUsers(users);
  };

  const getEvents = async() => {
    const { data: { events } } = await getEventsData();

    await setEvents(events);
  }

  const handleSelectedEvent = id => {
    console.log(id);
  };

  const handleEditKeyPress = async (e, event, index) => {
    if(e.key === 'Enter'){
      await editEventData({
        id: event.id,
        name: e.target.value,
      });

      editableFields[index] = {
        ...event,
        editable: false,
      };
      setEditableFields(editableFields);
      await getEvents();
    }
  }

  const handleDeleteList = async id => {
    await deleteEventData({ id });
    await getEvents();
  }

  const changeEventActivityData = async body => {
    try {
      return executeQuery(changeEventActivityQuery, { body });

    } catch(error) {
      return Promise.reject(error);
    }
  }

  const handleActivityChange = async (e, id) => {
    await changeEventActivityData({
      id,
      active: e.target.checked,
    });

    await getEvents();
  }

  const handleUserChange = (e, value) => {
    setUser(value);
  };

  const handleUploadChange = value => {
    if (!!value) setError(null);
    setUploadValue([ ...value ]);
  };

  const onSubmit = async() => {
    // const insertId = await createNewFoodProductData({ foodProductData: productData });
    submit();
    // check if form complete
    // console.log(newEventValues);
    // setDataInsertId(insertId);
  };


  const renderForm = () => {
    return (
      <Form
        onSubmit={onSubmit}
        render={({handleSubmit, form, submitting, pristine, values}) => {
          submit = handleSubmit
          // setNewEventValues(values);
          return (
            <form onSubmit={handleSubmit} className={classes.newEventForm}>
              <Grid>
                <Grid container alignItems="flex-start" spacing={2}>
                  {getFormFields(users, handleUserChange).map((item, idx) => (
                    <Grid item xs={item.size} key={idx}>
                      {item.field}
                    </Grid>
                  ))}
                  <Grid item xs="4">
                    <Uploader
                      action={`${process.env.REACT_APP_API_BASE_URL}/admin/upload`}
                      onChange={handleUploadChange}
                      autoUpload={false}
                      listType="picture-text"
                      fileList={uploadValue}
                      ref={refUploader}
                      withCredentials={true}
                      data={{context: 'events', id: dataInsertId}}
                    >
                      <button>
                        <Icon icon='camera-retro' size="lg"/>
                      </button>
                    </Uploader>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
    )
  }


  return (
    <div className={classes.contentContainer}>
      <SimpleModal
        isOpened={newEventModalVisibility}
        onClose={() => setNewEventModalVisibility(false)}
        title="Create new streaming event"
        submit={onSubmit}
        submitButtonText="Add new event"
      >
        {renderForm()}
      </SimpleModal>
      <div className={classes.contentContainer}>
          <Typography
            variant="h6"
            className={classes.addNewEventToolbar}
            onClick={() => setNewEventModalVisibility(true)}
          >
            <Add /><h6>EVENT</h6>
          </Typography>
        </div>
          <List>
            {events.map((event, index) => (
              <ListItem
                onClick={() => handleSelectedEvent(event.id)}
                className={classes.listItem}
                ContainerComponent="li"
              >
                <ListItemIcon>
                  <ViewStream />
                </ListItemIcon>

                {
                  editableFields[index]?.editable
                    ?
                    <TextField
                      id="standard-basic"
                      onKeyPress={e => handleEditKeyPress(e, event, index)}
                      defaultValue={event.name}
                    />
                    : <div>
                      <ListItemText primary={event.name}/>
                    </div>
                }

                <ListItemSecondaryAction>
                  <Tooltip title={!!event.active ? 'Activated' : 'Disactivated'}>
                    <Checkbox
                      checked={!!event.active}
                      onChange={e => handleActivityChange(e, event.id)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      style ={{
                        color: 'rgb(80,129,115)',
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Remove">
                    <IconButton onClick={() => handleDeleteList(event.id)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
    </div>
  );
}
