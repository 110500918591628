import executeQuery from '../../data/api';
import { loginQuery } from '../queries';


const login = async (formValues, history) => {
  try {
    const loginResult = await executeQuery(loginQuery, {
      body: formValues,
    });

    if(loginResult.status === 200) {
      history.push('/recipes');
    }
  } catch(error) {
    return Promise.reject(error);
  }
};

export {
  login,
};


