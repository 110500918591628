import executeQuery from '../../data/api';
import { getCommentsQuery, saveRecipeCommentModerationStatusQuery } from '../queries';


const saveRecipeCommentModerationStatus = async (status, commentId) => {
  try {
    return executeQuery(saveRecipeCommentModerationStatusQuery, {
      body: {
        status,
        commentId,
      }
    });
  } catch(error) {
    return Promise.reject(error);
  }
};

const getComments = async ([ dateFrom, dateTo ]) => {
  try {
    return executeQuery(getCommentsQuery, {
      params: {
        dateFrom,
        dateTo,
      }
    });
  } catch(error) {
    return Promise.reject(error);
  }
};

export {
  getComments,
  saveRecipeCommentModerationStatus,
};

