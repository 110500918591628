import { makeStyles } from '@material-ui/core/styles';


export const useStyles =  makeStyles((theme) => ({
  contentContainer: {
    maxWidth: 1000,
    margin: '0 auto',
    marginTop: 30,
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  openingHoursContainer: {
    border: '1px solid lightgrey',
    borderRadius: '5px',
    marginTop: '10px',
    padding: '0 0 10px 10px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
  },
  nutritionalValuesRow: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    margin: '0 0 10px 20px !important',

    '& label': {
      fontSize: '11px',
    },

    '& input': {
      fontSize: '11px',
    },

    '& .MuiInputBase-root': {
      marginRight: '14px',
    },

    '& svg': {
      marginTop: '16px',
      position: 'absolute',
      right: '10px',
    },
  },
  nutritionalValuesContainer: {
    display: 'inline-block',
    border: '1px solid lightgray',
    borderRadius: '5px',
    overflow: 'scroll',
    height: '300px',
    marginTop: '40px',

    '& h6': {
      padding: '10px 0 0 20px',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '46%',
  },
  modalContentContainer: {
    display: 'flex',
    width: '100% !important',
    justifyContent: 'space-between',
  },
  optionsContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    marginBottom: 40,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 40,
  },
  placesHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 40,
    width: '100%',
  },
  addTagButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginRight: 30,
  },
  listsWrapper: {
    display: 'flex',
    flexDirection: 'column',

    '& h3': {
      marginBottom: 30,
    },
  },
  list: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkbox: {
    color: 'rgb(80, 129, 115) !important',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 120,
    marginRight: 50,
  },
  productItem: {
    marginRight: 10,
  },
  secondaryRoot: {
    right: '-300px !important',
  },
  productTextField: {
    width: 250,
  },
  '.rsUploader': {
    marginTop: '30px',
  },
  switchConteiner: {
    display: 'flex',
    alignItems: 'center',
  },
  uploaderContainer: {
    marginTop: '10px',
    display: 'flex',
  },
  openingHoursPerDayContainer: {
    display: 'flex',
    justifyContent: 'space-around !important',
    height: '50px',
  },
  fromToField: {
    width: '40%'
  }
}));
