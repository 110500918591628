import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(theme => ({
  root: {
    width: 600,
    margin: '0 auto',
    marginTop: 20,
    marginBottom: 20,
    overflow: 'visible',
  },
}));
