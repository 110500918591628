import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(theme => ({
  contentContainer: {
    maxWidth: 700,
    margin: '0 auto',
    marginTop: 50,

    '& .MuiSvgIcon-root': {
      marginTop: 5,
    },
  },
  ingredients: {
    marginTop: 50,
  },
  stages: {
    marginTop: 50,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '30px 0',

    '& button': {
      marginRight: 10,
    }
  },
  textField: {
    '& input': {
      color: 'red !important',
    }
  },
  previewImg: {
    height: 100,
  },
  listPreviewImg: {
    width: '100%',
    marginTop: 4,
  },
  header: {
    marginBottom: 50,
  },
  fieldsContentContainer: {
    maxHeight: '90vh',
    overflow: 'scroll',
  },
  stagesTextField: {
    paddingTop: '0px !important',
  },
  stageContainer: {
    marginBottom: '20px',
  },
  stageHeader: {
    paddingBottom: '0 !important',
  },
  dialog: {
    margin: '20px 30px',
    '& svg': {
      marginRight: '10px',
    },
  },
}));
