import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  contentContainer: {
    maxWidth: 650,
    margin: '0 auto',
    marginTop: 100,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginTop: 2,
    },
  },
  addNewEventToolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 16,

    '& svg': {
      marginRight: 5,
    },

    '& h6': {
      cursor: 'pointer',
      fontSize: 20,
    },
  },
  header: {
    marginBottom: 30,
  },
  newEventForm: {
    marginBottom: 40,
  },
}));
