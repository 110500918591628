const getListsQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/lists?lang=:lang`,
};

const saveListsQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/lists/recipe`,
}

const saveListsOrderQuery = {
  method: 'PATCH',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/lists/order`,
}

const createListQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/lists`,
}

const deleteListQuery = {
  method: 'DELETE',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/lists`,
}

const editListQuery = {
  method: 'PATCH',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/lists`,
}

const changeListActivityQuery = {
  method: 'PATCH',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/lists/activity`,
}

const saveListQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/lists/tags`,
}

const getListTagsQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/lists/tags?listId=:listId`,

}

export {
  changeListActivityQuery,
  createListQuery,
  deleteListQuery,
  editListQuery,
  getListsQuery,
  getListTagsQuery,
  saveListQuery,
  saveListsQuery,
  saveListsOrderQuery,
};
