import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'rsuite';
import { io } from 'socket.io-client';
import Button from '@material-ui/core/Button';
import { FixedSizeList as List } from 'react-window';
import  orderBy from 'lodash.orderby';
import { format } from 'date-fns';
import axios from 'axios';


import { getComments, saveRecipeCommentModerationStatus } from '../../data/controllers';
import { MODERATION_ACTIONS } from '../../constants/recipes';

import { useStyles } from './moderation.style';
import 'rsuite/dist/styles/rsuite-default.css';


const socket = io(process.env.REACT_APP_API_BASE_URL, { transports : ['websocket'] });


export const Moderation = () => {
  const [ messages, setMessages ] = useState([]);
  const [ datesRange, setDatesRange ] = useState([ new Date(
    new Date().setHours(0,0,0,0)).toISOString(),
    new Date(new Date().setHours(24,0,0,0)).toISOString() ]);
  const classes = useStyles();

  useEffect(() => {
    const getCommentsItems = async() => {
      const { data: { comments } } = await getComments(datesRange);

      setMessages(comments);
    }

    getCommentsItems();
  }, [
    datesRange,
  ]);

  socket.on('NEW_RECIPE_COMMENT', data => {
    const comments = [
      data,
      ...messages,
    ];

    if(data?.comment) {
      setMessages(comments);
    }
  });

  const changeProperties = (message, commentId, status, sentiment) => {
    if(message.id === commentId) {
      message.admin_status = status;
      message.sentiment = sentiment;

      return message;
    }

    return message;
  };

  const handleModerationStatus = async (status, commentId, sentiment) => {
    await saveRecipeCommentModerationStatus(status, commentId);

    messages.forEach(message => changeProperties(message, commentId, status, sentiment));
    setMessages([ ...messages ]);
  };

  const renderModerationActions = message => {
    return MODERATION_ACTIONS.map((action, idx) => {
      const selectedActionStyles = {
        border: '1px solid lightgray',
        backgroundColor: action.color,
        color: '#FFF',
      };

      return (
        <Button
          style={message.admin_status === action.type ? selectedActionStyles : {}}
          className={classes.actionItem}
          onClick={() => handleModerationStatus(action.type, message.id, message.sentiment)}
          key={action.type + idx}
        >
          {action.type}
        </Button>
      );
    });
  }

  const Row = ({ index, style, data }) => {
    const message = data[index];

    return (
      <div style={{ ...style }} className={classes.listRow}>
         <p className={classes.dateColumn}>{format(new Date(message.created_at), 'H:mm, do LLL y')}</p>
         <p className={classes.commentColumn}>{message.comment}</p>
         <h6 className={classes.sentimentColumn}>{message.sentiment}</h6>
         <div className={classes.actionsColumn}>
           {renderModerationActions(message)}
         </div>
       </div>
    );
  }

  const handleDatesRangeChange = ([ dateFrom, dateTo ]) => {
    setDatesRange([
      dateFrom.toISOString(),
      dateTo.toISOString(),
    ]);
  };

  return (
    <div className={classes.contentContainer}>
      <DateRangePicker
        appearance="default"
        placeholder="Choose dates range"
        onChange={handleDatesRangeChange}
        className={classes.rangePicker}
      />
      <div className={classes.commandsHeader}>
        <p className={classes.dateColumn}>Date</p>
        <p className={classes.commentColumn}>Comment</p>
        <p className={classes.sentimentColumn}>AI sentiment</p>
        <p className={classes.actionsColumn}>Actions</p>
      </div>
      <List
        height={500}
        itemCount={messages?.length}
        itemSize={50}
        width={1000}
        itemData={orderBy(messages, ['id'], ['desc'])}
      >
        {Row}
      </List>
    </div>
  );
}
