const saveRecipeCommentModerationStatusQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/moderation/recipes/comment`,
}

const getCommentsQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/moderation/comments?date_from=:dateFrom&date_to=:dateTo`,
}

export {
  getCommentsQuery,
  saveRecipeCommentModerationStatusQuery,
};
