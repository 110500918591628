import executeQuery from '../../data/api';
import { getRecipeQuery, saveStatusQuery, saveRecipeQuery, scrapRecipeQuery } from '../queries';


const getRecipeData = async (offset, selectedStatus, recipesLang) => {
  try {
    return executeQuery(getRecipeQuery, { params: { offset, selectedStatus, recipesLang }});
  } catch(error) {
    return Promise.reject(error);
  }
};

const saveStatusData = async body => {
  try {
    return executeQuery(saveStatusQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

const saveRecipeDate = async body => {
  try {
    return executeQuery(saveRecipeQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
}

const scrapRecipeData = async body => {
  try {
    return executeQuery(scrapRecipeQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
}


export {
  getRecipeData,
  saveStatusData,
  saveRecipeDate,
  scrapRecipeData,
};


