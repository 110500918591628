import React, { Fragment, useEffect, useState } from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Checkbox,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  RootRef,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ListIcon from '@material-ui/icons/List';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Delete, Edit, SaveAlt } from '@material-ui/icons';

import {
  changeListActivityData,
  createListData,
  deleteListData,
  editListData,
  getListsData,
  getListTagsData,
  getTagsData,
  saveListData,
  saveListsOrderData,
} from '../../data/controllers';

import { SelectedTags, SimpleModal } from '../../components';



const useStyles = makeStyles((theme) => ({
  listItem: {
    marginRight: 10,
  },
  contentContainer: {
    maxWidth: 600,
    margin: '0 auto',
    marginTop: 100,
  },
  createButton: {
    backgroundColor: 'rgb(80,129,115)',
    color: '#FFF',
    '&:hover': {
      background: 'rgb(56,97,87)',
    },
  },
  addListButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',

  },
  listTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 40,
  },
  link: {
    fontSize: '1.25rem',
  },
  listRow: {
    backgroundColor: 'rgba(166, 201, 191, 0.3)',
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 14,
    marginTop: 100,
  },
  tags: {
    backgroundColor: 'rgba(157, 203, 193, 0.09)',
    margin: '0 20px 12px 0',
    padding: '6px 16px',
    borderRadius: 6,
    cursor: 'pointer',
  },
  tagsSelected: {
    backgroundColor: 'rgb(80, 129, 115)',
    color: '#FFF',
    margin: '0 20px 12px 0',
    padding: '6px 16px',
    borderRadius: 6,
    cursor: 'pointer',
  },
  bottomNavigation: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',

    '& button': {
      maxWidth: '100%',
    },
  }
}));

const languages = [
  {
    name: 'Polish',
    shortcut: 'pl',
  },
  {
    name: 'English',
    shortcut: 'en',
  },
  {
    name: 'Norwegian',
    shortcut: 'no',
  },
];


export const Lists = () => {
  const [lists, setLists] = useState([]);
  const [language, setLanguage] = useState('pl');
  const [newListModalVisibility, setNewListModalVisibility] = useState(false);
  const [newListName, setNewListName] = useState(null);
  const [editableFields, setEditableFields] = useState([]);
  const [selectedList, setSelectedList] = useState(null);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const classes = useStyles();

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
      background: 'rgb(235,235,235)',
    })
  });


  const reorder = (listItems, startIndex, endIndex) => {
    const result = Array.from(listItems);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getLists = async lang => {
    const { data: { lists } } = await getListsData(lang);
    const sortedLists = lists.sort((a,b) => a.order - b.order);

    await setLists(sortedLists);
  }

  const sendOrder = async updatedLists => {
    await saveListsOrderData({ updatedLists });
  }

  useEffect(async() => {
    getLists(language);

    const { data: { tags } } = await getTagsData();
    setTags(tags);
  }, []);

  const onDragEnd = async result => {
    if (!result.destination) {
      return;
    }

    const reorderedLists = reorder(
      lists,
      result.source.index,
      result.destination.index
    );

    const updatedListsWithIndex = reorderedLists.map((item, idx) => {
      return {
        ...item,
        order: idx,
      };
    });

    await setLists(updatedListsWithIndex);
    await sendOrder(updatedListsWithIndex);
    await getLists(language);
  }

  const onLanguageChange = e => {
    setLanguage(e.target.value);
    getLists(e.target.value);
    setSelectedTags([]);
  }

  const renderLanguageOptions = () => {
    return languages.map(({ name, shortcut }, idx) => {
      return <MenuItem
        key={`${shortcut}_${idx}`}
        value={shortcut}
      >
        {name}
      </MenuItem>
    });
  }

  const createNewList = async() => {
    await createListData({
      name: newListName,
      language,
      order: lists.length,
    });

    await setNewListModalVisibility(false);
    await getLists(language);
  }

  const handleDeleteList = async id => {
    await deleteListData({ id });
    await getLists(language);
  }

  const handleUpdateList = async list => {
    const updatedList = lists.map(listItem => {
      if(listItem.id === list.id) {
        return {
          ...listItem,
          editable: true,
        };
      }

      return {
        ...listItem,
        editable: false,
      }
    });

    setEditableFields(updatedList);
  }

  const handleEditKeyPress = async (e, list, index) => {
    if(e.key === 'Enter'){
      await editListData({
        id: list.id,
        name: e.target.value,
      });

      editableFields[index] = {
        ...list,
        editable: false,
      };
      setEditableFields(editableFields);
      await getLists(language);
    }
  }

  const handleActivityChange = async (e, id) => {
    await changeListActivityData({
      id,
      active: e.target.checked,
    });

    await getLists(language);
  }

  const handleSelectedList = async listId => {
    const { data: { tagsIds } } = await getListTagsData({ listId });
    const filteredTags = tags.filter(tagItem =>  tagsIds.includes(tagItem.id));

    setSelectedTags(filteredTags);
    setSelectedList(listId);
  }

  const handleSave = async() => {
    const tagsIds = selectedTags.map(tag => tag.id);
    await saveListData({ listId: selectedList, tagsIds });
  }

  return (
    <Fragment>
      <SimpleModal
        isOpened={newListModalVisibility}
        onClose={() => setNewListModalVisibility(false)}
        title="Create new list"
      >
        <TextField
          label="Name"
          onChange={e => setNewListName(e.target.value)}
        />
        <FormControl className={classes.modalFormControl}>
          <InputLabel id="language">Language</InputLabel>
          <Select
            labelId="language"
            value={language}
            onChange={e => setLanguage(e.target.value)}
          >
            {renderLanguageOptions()}
          </Select>
        </FormControl>
        <Button variant="contained" onClick={createNewList} className={classes.createButton}>
          Create
        </Button>
      </SimpleModal>
      <div className={classes.contentContainer}>
        <div className={classes.listsHeader}>
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={language}
              onChange={onLanguageChange}
            >
              {renderLanguageOptions()}
            </Select>
            <FormHelperText>Choose lists language</FormHelperText>
          </FormControl>
          <Typography
            variant="h6"
            onClick={() => setNewListModalVisibility(true)}
            className={classes.addListButton}
          >
            <Add />LIST
          </Typography>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <RootRef rootRef={provided.innerRef}>
                <List>
                  {lists.map((list, index) => (
                    <Draggable
                      key={list.id}
                      draggableId={list.id.toString()}
                      index={index}
                      onClick
                    >
                      {(provided, snapshot) => (
                        <ListItem
                          onClick={() => handleSelectedList(list.id)}
                          className={list.id === selectedList && classes.listRow}
                          ContainerComponent="li"
                          ContainerProps={{ ref: provided.innerRef }}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <ListItemIcon>
                            <ListIcon />
                          </ListItemIcon>

                          {
                            editableFields[index]?.editable
                              ?
                                <TextField
                                  id="standard-basic"
                                  onKeyPress={e => handleEditKeyPress(e, list, index)}
                                  defaultValue={list.name}
                                />
                              : <div className={classes.listTextContainer}>
                                  <ListItemText primary={list.name} className={classes.listItem}/>
                                  <span style={{ color: 'rgb(80,129,115)' }}>({list.recipesCount})</span>
                                </div>
                          }

                          <ListItemSecondaryAction>
                            <Tooltip title={!!list.active ? 'Activated' : 'Disactivated'}>
                              <Checkbox
                                checked={!!list.active}
                                onChange={e => handleActivityChange(e, list.id)}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                style ={{
                                  color: 'rgb(80,129,115)',
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Edit">
                              <IconButton onClick={() => handleUpdateList(list)}>
                                <Edit />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Remove">
                              <IconButton onClick={() => handleDeleteList(list.id)}>
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              </RootRef>
            )}
          </Droppable>
        </DragDropContext>
        <SelectedTags
          visible={!!selectedList}
          tags={tags}
          preselectedTags={selectedTags}
          onSelectedTags={selectedTags => setSelectedTags(selectedTags)}
        />
      </div>
      {
        !!selectedList && (
          <BottomNavigation showLabels className={classes.bottomNavigation} onClick={handleSave}>
            <BottomNavigationAction label="Save" icon={<SaveAlt />}/>
          </BottomNavigation>
        )
      }
    </Fragment>
  );
}
