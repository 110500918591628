const getEventsQuery = {
  method: 'GET',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/events`,
};

const saveEventsQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/events`,
}

const createEventQuery = {
  method: 'POST',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/event`,
}

const deleteEventQuery = {
  method: 'DELETE',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/events`,
}

const editEventQuery = {
  method: 'PATCH',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/event`,
}

const changeEventActivityQuery = {
  method: 'PATCH',
  url: `${process.env.REACT_APP_API_BASE_URL}/admin/events/activity`,
}


export {
  changeEventActivityQuery,
  createEventQuery,
  deleteEventQuery,
  editEventQuery,
  getEventsQuery,
  saveEventsQuery,
};
