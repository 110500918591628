import executeQuery from '../../data/api';
import {
  changeFoodProductActivityQuery,
  deleteFoodProductQuery,
  editFoodProductQuery,
  getFoodProductsQuery,
  createNewFoodProductQuery,
  getFoodProvidersQuery,
  changeFoodProviderActivityQuery,
  editFoodProviderQuery,
  deleteFoodProviderQuery,
  createNewFoodProviderQuery,
  getFoodStoresQuery,
  changeFoodStoreActivityQuery,
  editFoodStoreQuery,
  deleteFoodStoreQuery,
  createNewFoodStoreQuery,
  getPlacesQuery,
  requestNewPlaceQuery,
  createNewPlaceQuery,
  deletePlaceQuery,
  editPlaceQuery,
} from '../queries/local';


const getFoodProductsData = async() => {
  try {
    return executeQuery(getFoodProductsQuery);

  } catch(error) {
    return Promise.reject(error);
  }
};

const changeFoodProductActivityData = async body => {
  try {
    return executeQuery(changeFoodProductActivityQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

const editFoodProductData = async body => {
  try {
    return executeQuery(editFoodProductQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

const deleteFoodProductData = async body => {
  try {
    return executeQuery(deleteFoodProductQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

const createNewFoodProductData = async ({ foodProductData }) => {
  try {
    const { data: { insertId }} = await executeQuery(createNewFoodProductQuery, { body: { foodProductData } });
    return insertId;
  } catch(error) {
    return Promise.reject(error);
  }
}

const getFoodProvidersData = async() => {
  try {
    return executeQuery(getFoodProvidersQuery);

  } catch(error) {
    return Promise.reject(error);
  }
};

const changeFoodProviderActivityData = async body => {
  try {
    return executeQuery(changeFoodProviderActivityQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

const editFoodProviderData = async body => {
  try {
    return executeQuery(editFoodProviderQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

const deleteFoodProviderData = async body => {
  try {
    return executeQuery(deleteFoodProviderQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

const createNewFoodProviderData = async ({ foodProviderData }) => {
  try {
    const { data: { insertId }} = await executeQuery(createNewFoodProviderQuery, { body: { foodProviderData } });
    return insertId;
  } catch(error) {
    return Promise.reject(error);
  }
}

const getFoodStoresData = async() => {
  try {
    return executeQuery(getFoodStoresQuery);

  } catch(error) {
    return Promise.reject(error);
  }
};

const changeFoodStoreActivityData = async body => {
  try {
    return executeQuery(changeFoodStoreActivityQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

const editFoodStoreData = async body => {
  try {
    return executeQuery(editFoodStoreQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

const deleteFoodStoreData = async body => {
  try {
    return executeQuery(deleteFoodStoreQuery, { body });
  } catch(error) {
    return Promise.reject(error);
  }
};

const createNewFoodStoreData = async ({ foodStoreData }) => {
  try {
    const { data: { insertId }} = await executeQuery(createNewFoodStoreQuery, { body: { foodStoreData } });
    return insertId;
  } catch(error) {
    return Promise.reject(error);
  }
}

const getPlacesData = async country => {
  try {
    const data = await executeQuery(getPlacesQuery, {
      params: {
        country,
      },
    });
    return data;
  } catch(error) {
    return Promise.reject(error);
  }
};

const requestNewPlaceData = async placeId => {
  try {
    const { data: { place } } = await executeQuery(requestNewPlaceQuery, { body: { place_id: placeId } });
    return place;
  } catch(error) {
    return Promise.reject(error);
  }
}

const createNewPlaceData = async data => {
  try {
    const { data: { place: { id } } } = await executeQuery(createNewPlaceQuery, { body: { 
      ...data,
      opening_hours: JSON.stringify(data.opening_hours)
    },
      params: {
        id: data.id,
      }
    });

    return id;
  } catch(error) {
    return Promise.reject(error);
  }
}


const deletePlaceData = async body => {
  try {
    return executeQuery(deletePlaceQuery, { params: {
      id: body.id,
    }});
  } catch(error) {
    return Promise.reject(error);
  }
};


const editPlaceData = async data => {
  try {
    const { data: { place: { id } } } = await executeQuery(editPlaceQuery, { body: {
      ...data,
      opening_hours: JSON.stringify(data.opening_hours),
    }, params: {
      id: data.id,
    }});

    return id;
  } catch(error) {
    return Promise.reject(error);
  }
};

export {
  getFoodProductsData,
  changeFoodProductActivityData,
  editFoodProductData,
  deleteFoodProductData,
  createNewFoodProductData,
  getFoodProvidersData,
  changeFoodProviderActivityData,
  editFoodProviderData,
  deleteFoodProviderData,
  createNewFoodProviderData,
  getFoodStoresData,
  changeFoodStoreActivityData,
  editFoodStoreData,
  deleteFoodStoreData,
  createNewFoodStoreData,
  getPlacesData,
  requestNewPlaceData,
  createNewPlaceData,
  deletePlaceData,
  editPlaceData,
};
